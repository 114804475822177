import theme from '@/theme'
import { Button, ButtonProps, styled } from '@mui/material'
import React from 'react'

const StyledButton = styled(Button)({
  '&.MuiButton-contained': {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,
  },
  '&.MuiButton-outlined': {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
})

export interface ErrorButtonProps extends ButtonProps {}

const ErrorButton = ({ children, ...others }: ErrorButtonProps) => <StyledButton {...others}>{children}</StyledButton>

export default ErrorButton
