/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useRef, useCallback } from 'react'
import { Box, styled, Typography, TypographyProps } from '@mui/material'
import { useSpring, animated } from 'react-spring'

export interface SpringTextProps extends TypographyProps {}

const StyledTypography = styled(Typography)({
  whiteSpace: 'nowrap',
  textOverflow: 'clip',
})

const SpringText = ({ children, ...rest }: SpringTextProps) => {
  const ref = useRef<HTMLSpanElement>({ offsetWidth: 0, scrollWidth: 0 } as HTMLSpanElement)

  const animation = async (next: any) => {
    await next({
      reset: true,
      from: { transform: `translateX(0px)` },
    })
    await new Promise((resolve) => setTimeout(() => resolve(true), 3000))
    if (ref.current?.offsetWidth < ref.current?.scrollWidth) {
      const scrollWidth = ref.current.scrollWidth
      await next({
        reset: true,
        config: { duration: scrollWidth * 30 },
        from: { transform: `translateX(0px)` },
        transform: `translateX(-${scrollWidth + 16}px)`,
      })
    }
    await animation(next)
  }

  const style = useSpring({
    from: { transform: `translateX(0px)` },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    to: useCallback(animation, [children]),
  })

  const text = useMemo(
    () => (
      <Box overflow="hidden">
        <animated.div style={style}>
          <StyledTypography ref={ref} {...rest}>
            {children}
          </StyledTypography>
        </animated.div>
      </Box>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [children],
  )

  return <>{text}</>
}

export default SpringText
