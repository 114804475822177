import React from 'react'
import { connectStateResults } from 'react-instantsearch-dom'
import { StateResultsProvided } from 'react-instantsearch-core'
import { SearchFormHitList } from '@/components/atoms'
import { Box } from '@mui/material'
import { HitAudioBook } from '@/libs/Algolia'

interface ResultsProps extends Partial<StateResultsProvided<HitAudioBook>> {
  children?: React.ReactNode
}

const Results = ({ searchState, error, searchResults, children }: ResultsProps) => (
  <>
    {
      // TODO bugsnag等導入時、仕様に沿ったエラーログを出力すること
      error ? (
        <SearchFormHitList className="hits">
          <Box padding="0 .5rem">問題が発生しています。しばらしくてからお試しください。</Box>
        </SearchFormHitList>
      ) : searchState?.query ? (
        searchResults?.nbHits >= 0 ? (
          children
        ) : null // not found
      ) : null // no query
    }
  </>
)

export default connectStateResults<ResultsProps>(Results)
