import React from 'react'
import { IconButton, IconButtonProps } from '@mui/material'
import { MenuRounded } from '@mui/icons-material'

interface MenuIconButtonProps extends IconButtonProps {}

const MenuIconButton = (props: MenuIconButtonProps): JSX.Element => {
  return (
    <IconButton {...props} size="large">
      <MenuRounded fontSize="large" />
    </IconButton>
  )
}

export default MenuIconButton
