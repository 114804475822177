import React from 'react'
import { Box, styled } from '@mui/material'
import { useWebP } from '@/contexts/webp'

const ShadowImg = styled('img')({
  borderRadius: '4px',
  boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.32)',
  width: '100%',
  height: '100%',
})

export interface UppingImgProps {
  width: number
  coverArt: Queries.audiobookInfoQuery['contentfulAudiobook']['coverArt']
  title: string
}
const UppingImg = ({ width, coverArt, title }: UppingImgProps) => {
  const { src, srcSet, calculatedHeight } = useWebP()
  if (!coverArt) {
    return <></>
  }
  const height = calculatedHeight(coverArt, width)
  return (
    <Box width={width} height={height}>
      <ShadowImg src={src(width, height, coverArt)} srcSet={srcSet(width, height, coverArt)} alt={title} />
    </Box>
  )
}

export default UppingImg
