import React from 'react'
import { Box, Typography, styled } from '@mui/material'
import { CenteringImg } from '@/components/atoms'

const AuditionWrap = styled(Box)({
  position: 'absolute',
  zIndex: 2,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
})

const AuditionTypography = styled(Typography)({
  marginTop: '-2px',
  fontSize: '1.2rem',
  color: '#ffffff',
  fontWeight: 'bold',
})

const StyledPicture = styled(Box)({
  position: 'relative',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  '&:before': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(51, 178, 228, .7)',
    backdropFilter: ' blur(3px)',
    borderRadius: '4px 4px 0 0',
  },
})

const StyledImg = styled('img')({
  display: 'inline-block',
  width: '3.4rem',
})

export interface DuringAuditionImgProps {
  width: number | string
  height: number | string
  artWidth: number | string
  artHeight: number | string
  coverArt: Queries.audiobookInfoQuery['contentfulAudiobook']['coverArt']
  title: Queries.audiobookInfoQuery['contentfulAudiobook']['title']
}

const DuringAuditionImg = ({ width, height, artWidth, artHeight, coverArt, title }: DuringAuditionImgProps) => {
  return (
    <>
      <AuditionWrap width={width} height={height}>
        <StyledImg
          src={`/assets/images/audiobook/trial_equalizer.png`}
          srcSet={
            '/assets/images/audiobook/trial_equalizer_2x.png 2x, /assets/images/audiobook/trial_equalizer_3x.png 3x'
          }
          alt={`試聴中-${title}`}
        />
        <AuditionTypography>試聴中</AuditionTypography>
      </AuditionWrap>
      <StyledPicture width={width} height={height}>
        <CenteringImg width={Number(artWidth)} height={Number(artHeight)} coverArt={coverArt} title={title} />
      </StyledPicture>
    </>
  )
}

export default DuringAuditionImg
