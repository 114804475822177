import theme from '@/theme'
import { Snackbar, SnackbarCloseReason, SnackbarProps, Slide, styled } from '@mui/material'
import { Alert } from '@mui/material'
import React from 'react'

export interface ErrorSnackbarProps extends Omit<SnackbarProps, 'children' | 'onClose'> {
  children: React.ReactNode

  onClose?: (event: React.SyntheticEvent, reason?: SnackbarCloseReason) => void
}

const ErrorAlert = styled(Alert)({
  backgroundColor: theme.palette.error.main,
  color: theme.palette.error.contrastText,
  textAlign: 'left',

  '& svg': {
    fill: theme.palette.error.contrastText,
  },

  '& .MuiAlert-action': {
    margin: 'auto',
    '& .MuiSvgIcon-root': {
      fontSize: '2rem',
    },
  },
})

const ErrorSnackbar = ({ children, ...others }: ErrorSnackbarProps) => (
  <Snackbar
    {...others}
    TransitionComponent={Slide}
    autoHideDuration={5000}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
  >
    <ErrorAlert severity="error" onClose={others.onClose}>
      {children}
    </ErrorAlert>
  </Snackbar>
)

export default ErrorSnackbar
