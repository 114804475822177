import React, { ImgHTMLAttributes } from 'react'
import theme from '@/theme'

export interface ResponsiveImageProps extends ImgHTMLAttributes<HTMLImageElement> {}

/**
 * 画像をPCとSPで切り替えるコンポーネント
 * 普段はあまり書きませんが画像配置が特殊なためコメントしています
 * src には /assets 以下に配置した、PC用画像の等倍のパスを指定してください
 * また、同一フォルダにSP版、および 2x, 3x 画像を配置してください
 * ex. src="/assets/path/to/step01.png" の場合、
 *   pc: /assets/path/to/step01.png, /assets/path/to/step01@2x.png, /assets/path/to/step01@3x.png
 *   sp: /assets/path/to/step01-sp.png, /assets/path/to/step01-sp@2x.png, /assets/path/to/step01-sp@3x.png
 */
const ResponsiveImage = ({ src, alt, ...rest }: ResponsiveImageProps) => {
  const breakpoint = theme.breakpoints.values.md
  const ext = src.substring(src.lastIndexOf('.'))
  const pcPath = src.substring(0, src.lastIndexOf('.'))
  const spPath = `${pcPath}-sp`
  return (
    <picture>
      <source
        media={`(max-width:${breakpoint - 1}px)`}
        srcSet={`${spPath}${ext} 1x, ${spPath}@2x${ext} 2x, ${spPath}@3x${ext} 3x`}
      />
      <source
        media={`(min-width:${breakpoint}px)`}
        srcSet={`${pcPath}${ext} 1x, ${pcPath}${ext}, ${pcPath}@2x${ext} 2x, ${pcPath}@3x${ext} 3x`}
      />
      <img loading="lazy" src={src} alt={alt} {...rest} />
    </picture>
  )
}

export default ResponsiveImage
