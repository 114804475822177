import React, { useState } from 'react'
import { FormControl, FormLabel, FormControlLabel, RadioGroup, Radio, Divider, IconButton, styled } from '@mui/material'
import { DoneRounded } from '@mui/icons-material'
import Confirm from '@/components/organisms/Confirm'

const StyledFormControl = styled(FormControl)({
  display: 'block',
})

const StyledFormLabel = styled(FormLabel)({
  display: 'block',
  lineHeight: '1.6rem',
  padding: '1.6rem',
  marginTop: '0.8rem',
})

const StyledRadioGroup = styled(RadioGroup)({
  display: 'block',
  padding: '1.6rem',
})

const StyledFormControlLabel = styled(FormControlLabel)({
  display: 'block',
  height: '4.8rem',
  lineHeight: '4.8rem',
  margin: '0',
})

const StyledRadio = styled(Radio)({
  display: 'none',
})

const StyledIconButton = styled(IconButton)({
  width: '5.6rem',
  justifyContent: 'start',
  // MEMO: disableFocusRipple, disableRippleが効いていないので追記
  '&:hover': {
    backgroundColor: '#ffffff !important',
  },
  '&:active': {
    backgroundColor: '#ffffff !important',
  },
})

type SortMenuItem = {
  id: number
  label: string
}

type SortMenuItems = SortMenuItem[]

export const SORT_MENU: SortMenuItems = [
  {
    id: 0,
    label: '最新商品',
  },
  {
    id: 1,
    label: '発売日が古い順',
  },
  {
    id: 2,
    label: '価格が安い順',
  },
  {
    id: 3,
    label: '価格が高い順',
  },
  {
    id: 4,
    label: '再生時間の短い順',
  },
  {
    id: 5,
    label: '再生時間の長い順',
  },
]

export const SORT_MENU_DEFUALT_VALUE = 0

export interface SortMenuOverlayProps {
  open: boolean
  onClose: () => void
  sortId: number
  setSortId: React.Dispatch<React.SetStateAction<number>>
}

const SortMenuOverlay = (props: SortMenuOverlayProps) => {
  const [sortId, setSortId] = useState(props.sortId)
  const onChangeSortId = (id: number) => {
    setSortId(id)
    props.setSortId(id)
  }
  return (
    <Confirm {...props}>
      <StyledFormControl>
        <StyledFormLabel focused={false}>並べ替え</StyledFormLabel>
        <Divider />
        <StyledRadioGroup defaultValue={SORT_MENU_DEFUALT_VALUE} aria-label="search-sort" name="search-sort">
          {SORT_MENU.map((item: SortMenuItem) => (
            <StyledFormControlLabel
              key={item.id}
              value={item.id}
              label={item.label}
              control={
                <>
                  <StyledRadio onChange={() => onChangeSortId(item.id)} />
                  <StyledIconButton
                    color="primary"
                    onClick={() => onChangeSortId(item.id)}
                    disableFocusRipple={true}
                    disableRipple={true}
                  >
                    {sortId === item.id && <DoneRounded fontSize="large" />}
                  </StyledIconButton>
                </>
              }
            />
          ))}
        </StyledRadioGroup>
      </StyledFormControl>
    </Confirm>
  )
}

export default SortMenuOverlay
