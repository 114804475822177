import React from 'react'

const Docomo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
    <g id="ic_carrierLogin-docomo" transform="translate(-4 -4)">
      <rect width="22" height="22" transform="translate(4 4)" fill="none" opacity="0.48" />
      <path
        id="path12799"
        d="M12.674,16.612a2.247,2.247,0,0,1,2.293-2.343,2.221,2.221,0,0,1,2.294,2.282v.121a2.221,2.221,0,0,1-2.294,2.283A2.247,2.247,0,0,1,12.674,16.612Zm4.553-9.021v4.764a4.557,4.557,0,0,0-2.316-.6A4.768,4.768,0,0,0,10,16.613a4.769,4.769,0,0,0,4.911,4.853c2.409,0,3.309-1.164,4.989-1.935V7.591H17.227"
        transform="translate(-0.5 0.251)"
        fill="#c03"
      />
    </g>
  </svg>
)

export default Docomo
