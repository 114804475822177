import React, { SyntheticEvent } from 'react'
import { sendEvent } from '@/libs/GoogleTagManager'

interface GoogleTagManagerEventProps {
  category: string

  action: string

  label?: string

  value?: number

  children: React.ReactNode
}

const GoogleTagManagerEvent = ({ category, action, label, value, children }: GoogleTagManagerEventProps) => (
  <>
    {React.Children.map(children, (child) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const childElement = child as React.ReactElement<any>
      return React.cloneElement(childElement, {
        onClick: (e: SyntheticEvent) => {
          sendEvent(action, label, category, value)
          childElement.props.onClick && childElement.props.onClick(e)
        },
      })
    })}
  </>
)

export default GoogleTagManagerEvent
