import React from 'react'
import Audiobook from '@/libs/Audiobook'
import { AudiobookItem } from '@/components/organisms'
import { ItemRewardProps } from '@/components/organisms/Audiobook/Item'
import { List } from '@/components/atoms/Audiobook'
import { useConnectStates } from '@/contexts/connect'
import { useFavorite } from '@/contexts/favorite'

type Audiobooks = Queries.findSerialCodePageQuery['contentfulSerialCodePage']['reward']['audiobookList']['audiobooks']
type Audiobook = Audiobooks[0]
interface RewardTargetContentsProps {
  rewardOption: ItemRewardProps
  audiobooks?: Audiobooks
}

const RewardTargetContents = ({ audiobooks, rewardOption }: RewardTargetContentsProps) => {
  const collectionIds = audiobooks?.map((audiobook: Audiobook) => audiobook.collectionId) || []
  const { favorite } = useConnectStates([['favorite', { collectionIds }]])
  const { favoriteProps } = useFavorite()
  const { itemFavorite } = favoriteProps(favorite)

  return (
    <>
      {audiobooks?.length > 0 && (
        <List>
          {audiobooks.filter(Audiobook.available).map((audiobook: Audiobook, i: number) => (
            <AudiobookItem
              key={i}
              {...audiobook}
              reward={rewardOption}
              favorite={itemFavorite(audiobook.collectionId)}
            />
          ))}
        </List>
      )}
    </>
  )
}

export default RewardTargetContents
