import React, { useState } from 'react'
import { Box } from '@mui/material'
import { ImportExportRounded } from '@mui/icons-material'
import SortMenuOverlay from '@/components/organisms/Search/Sort/menu'
import { SORT_MENU } from '@/components/organisms/Search/Sort/menu'
import { DefaultButton } from '@/components/atoms'

export interface SortProps {
  sortId: number
  sorted: boolean
  setSortId: React.Dispatch<React.SetStateAction<number>>
  handleOpen?: () => void
}

const Sort = ({ sortId, sorted, setSortId, handleOpen }: SortProps) => {
  const [open, setOpen] = useState(false)

  const sort = (id: number) => {
    setSortId(id)
    setOpen(false)
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2,
        }}
      >
        <DefaultButton
          startIcon={<ImportExportRounded sx={{ width: '1.8rem' }} />}
          onClick={() => {
            setOpen(!open)
            handleOpen && handleOpen()
          }}
          sx={{
            fontWeight: 'bold',
            fontSize: '1.3rem',
          }}
        >
          {sorted ? SORT_MENU[sortId].label : '並べ替え'}
        </DefaultButton>
      </Box>
      <SortMenuOverlay open={open} onClose={() => setOpen(false)} setSortId={sort} sortId={sortId} />
    </>
  )
}

export default Sort
