import React, { ReactNode } from 'react'
import { Box, Typography, Button } from '@mui/material'
import { Confirm } from '@/components/organisms'
import { navigate } from 'gatsby'
import { useLocation } from '@reach/router'
import { DefaultButton } from '@/components/atoms'

export interface SigninOverlayProps {
  open: boolean
  overrideMessage?: ReactNode
  onClose: () => void
}
const SigninOverlay = (props: SigninOverlayProps) => {
  const location = useLocation()
  const path = location.pathname
  const { overrideMessage } = props

  return (
    <Confirm {...props}>
      <Box py={3} px={2}>
        {overrideMessage ? (
          overrideMessage
        ) : (
          <Typography variant="body1" color="textSecondary">
            ご購入の前にログインをお願いします。
            <br />
            アカウントをまだお持ちでない方は、アカウント作成へお進みください。
          </Typography>
        )}

        <Box textAlign="center" my={2}>
          <DefaultButton
            size="large"
            variant="contained"
            onClick={() => {
              navigate('/signin', { state: { redirectPath: path } })
              props.onClose?.()
            }}
          >
            ログイン
          </DefaultButton>
        </Box>
        <Box textAlign="center">
          <Button
            variant="contained"
            color="primary"
            size="large"
            href="/information/create_account"
            onClick={() => {
              props.onClose?.()
            }}
          >
            アカウント作成(初めての方はコチラ)
          </Button>
        </Box>
      </Box>
    </Confirm>
  )
}

export default SigninOverlay
