import algoliasearch from 'algoliasearch/lite'
import { SearchOptions, SearchResponse, MultipleQueriesQuery, MultipleQueriesResponse } from '@algolia/client-search'
import useSWR, { keyInterface } from 'swr'
import aa from 'search-insights'

export enum INSIGHT_METHOD_NAME {
  clickedObjectIDs = 'clickedObjectIDs',
  clickedObjectIDsAfterSearch = 'clickedObjectIDsAfterSearch',
  convertedObjectIDs = 'convertedObjectIDs',
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const searchInsightClient = aa as unknown as (functionName: string, ...functionArguments: any[]) => void

searchInsightClient('init', {
  appId: process.env.ALGOLIA_APPLICATION_ID,
  apiKey: process.env.ALGOLIA_API_KEY,
})

const algoliaClient = algoliasearch(process.env.ALGOLIA_APPLICATION_ID, process.env.ALGOLIA_API_KEY)

export const suggestionSearchOptions: SearchOptions = {
  analytics: true,
  attributesToRetrieve: ['objectID', 'title', 'authors'], // 検索結果として返す attributes
  restrictHighlightAndSnippetArrays: true, // 検索結果として返す heightlight テキストを restrictSearchableAttributes に一致させる
}

const indexSearchDefaultOptions: SearchOptions = {
  analytics: true,
  attributesToRetrieve: ['objectID'], // 検索結果として返す attributes
  getRankingInfo: true,
  restrictHighlightAndSnippetArrays: true, // 検索結果として返す heightlight テキストを restrictSearchableAttributes に一致させる
  clickAnalytics: true,
}

const createEmptyResult = (): SearchResponse<HitAudioBook> => {
  return {
    exhaustiveNbHits: true,
    hits: [],
    hitsPerPage: 5,
    nbHits: 0,
    nbPages: 0,
    page: 0,
    params: '',
    processingTimeMS: 0,
    query: '',
  }
}

const ALGOLIA_IINDEXES = [
  process.env.ALGOLIA_INDEX_NAME,
  process.env.ALGOLIA_INDEX_NAME_START_DATE_TIME,
  process.env.ALGOLIA_INDEX_NAME_RETAIL_PRICE_ASC,
  process.env.ALGOLIA_INDEX_NAME_RETAIL_PRICE_DESC,
  process.env.ALGOLIA_INDEX_NAME_DURATION_ASC,
  process.env.ALGOLIA_INDEX_NAME_DURATION_DESC,
]

/**
 * incremental search 用の SearchClient 生成
 *
 * InstantSearch のインクリメンタルサーチで文字列の指定がない時にAPIリクエストを実行しないようにする
 * see at: https://www.algolia.com/doc/guides/building-search-ui/going-further/conditional-requests/js/
 */
export const createSearchClient = () => {
  return {
    search(queries: readonly MultipleQueriesQuery[]): Readonly<Promise<MultipleQueriesResponse<HitAudioBook>>> {
      if (queries.every(({ params }: MultipleQueriesQuery) => !params.query)) {
        return Promise.resolve({
          results: queries.map(() => createEmptyResult()),
        })
      }
      return algoliaClient.search<HitAudioBook>(queries)
    },
  }
}

export const indexSearch = async (
  searchOptions: SearchOptions,
  sortId: number,
): Promise<SearchResponse<HitAudioBook>> => {
  const index = algoliaClient.initIndex(ALGOLIA_IINDEXES[sortId])
  const { query, ...options } = searchOptions

  const result = await index.search<HitAudioBook>(query, { ...indexSearchDefaultOptions, ...options })
  return result
}

export const useIndexSearch = (
  key: keyInterface,
  searchOptions: SearchOptions,
  sortId: number,
): [SearchResponse<HitAudioBook>?, Error?] => {
  const { data, error } = useSWR<SearchResponse<HitAudioBook>>(
    key + '?sort=' + sortId,
    async () => await indexSearch(searchOptions, sortId),
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      focusThrottleInterval: 1000,
    },
  )

  return [data, error]
}

// type definitions
type HitAuthor = {
  name: string
  yomikana: string
}

type HitNarrator = {
  name: string
  yomikana: string
}

type HitAudioBook = {
  objectID: string
  title: string
  titleYomikana: string
  marketingComment: string
  categories: string[]
  hierarchicalCategories: string[]
  type: 'Audiobook'
  image: string
  duration: number
  authors: HitAuthor[]
  narrators: HitNarrator[]
  retailPrice: number
  __queryID: string
  __position: number
}

export { HitAudioBook }
