import React from 'react'
import Pagination, { PaginationProps } from '@mui/material/Pagination'
import { PaginationItem } from '@mui/material'

import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded'
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded'

export interface PaginationRoundedProps extends PaginationProps {}

const PaginationRounded = (props: PaginationRoundedProps) => (
  <Pagination
    renderItem={(item) => (
      <PaginationItem components={{ previous: NavigateBeforeRoundedIcon, next: NavigateNextRoundedIcon }} {...item} />
    )}
    {...props}
  />
)

export default PaginationRounded
