import React from 'react'
import rehypeReact from 'rehype-react'
import * as prod from 'react/jsx-runtime'
import { Link, styled, Typography } from '@mui/material'
import { HeadingH1, HeadingH2, HeadingH3, HeadingH4 } from '@/components/atoms'
import { useHydrated } from 'react-hydration-provider'

const StyledParagraph = styled(Typography)({
  margin: '0 0 1.6rem 0',
})
const MDPara = ({ ...props }) => <StyledParagraph variant="body1" color="textSecondary" {...props} />

const MDLink = ({ ...props }) => <Link {...props} />

const MDH1 = ({ ...props }) => <HeadingH1 {...props} />

const MDH2 = ({ ...props }) => <HeadingH2 {...props} />

const MDH3 = ({ ...props }) => <HeadingH3 {...props} />

const MDH4 = ({ ...props }) => <HeadingH4 {...props} />

const MDStyle = ({ ...props }) => {
  // NOTE: cssに {content:"[any]";} が存在する場合に限定してReact Hydrationエラーが発生する対策
  const hydrated = useHydrated()
  const filter = (props: { [x: string]: string[] }) => {
    if (hydrated) {
      return props
    }
    return {
      children: props?.children?.map?.((css: string) => css.replaceAll(/content:.*".*";/gi, '')),
    }
  }
  return <style {...filter(props)}></style>
}

// eslint-disable-next-line
// @ts-ignore TS2339 jsx, jsxs, Fragment
const production = { Fragment: prod.Fragment, jsx: prod.jsx, jsxs: prod.jsxs }
// NOTE: rehype-reactのgatsbyでの使い方が間違っているらしいが、gatsby側が直っていないのでエラーを無視する
//       https://github.com/rehypejs/rehype-react/issues/24
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore TS7009
export const HtmlAst = new rehypeReact({
  ...production,
  components: {
    p: MDPara,
    a: MDLink,
    h1: MDH1,
    h2: MDH2,
    h3: MDH3,
    h4: MDH4,
    style: MDStyle,
  },
}).compiler

export default (tree: Record<string, unknown>) => HtmlAst(tree, {})
