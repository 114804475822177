import { Box, List, ListItem, ListItemIcon, ListItemText, ListItemButton } from '@mui/material'
import { ChevronRightRounded } from '@mui/icons-material'
import { WarningRounded } from '@mui/icons-material'
import theme from '@/theme'
import React from 'react'

interface UnregisteredEmailAlertProps {}

const UnregisteredEmailAlert = (_: UnregisteredEmailAlertProps) => (
  <List component={Box} width="100%" sx={{ py: 1, backgroundColor: '#FF9800' }}>
    <ListItemButton component="a" href={process.env.ACCOUNT_CENTER_URL} sx={{ p: 0 }}>
      <ListItem
        secondaryAction={<ChevronRightRounded sx={{ display: 'flex', color: theme.palette.primary.contrastText }} />}
        sx={{ py: 0 }}
      >
        <ListItemIcon>
          <WarningRounded style={{ color: theme.palette.primary.contrastText, fontSize: '2.4rem' }} />
        </ListItemIcon>
        <ListItemText
          sx={{
            py: 0,
          }}
          primaryTypographyProps={{
            variant: 'body2',
            color: theme.palette.primary.contrastText,
          }}
        >
          ご利用にはdwango IDが必須です。ドワンゴジェイピーのアカウント管理ページへ進み、「dwango
          IDの作成」を行ないましょう。
        </ListItemText>
      </ListItem>
    </ListItemButton>
  </List>
)

export default UnregisteredEmailAlert
