type EcommerceParams = {
  action: string

  collectionId: string

  title: string

  price: number

  categories?: { name?: string }[]
} & Record<string, unknown>

const sendEcommerce = ({ action, collectionId, title, price, categories, ...others }: EcommerceParams) => {
  window.dataLayer.push({
    event: action,
    items: [
      Object.assign(
        categories?.slice(1).reduce(
          (m, v, i) => {
            m[`category_${i}`] = v?.name
            return m
          },
          {} as Record<string, unknown>,
        ) || {},
        {
          id: collectionId,
          name: title,
          category: categories?.[0]?.name,
          price: price,
          quantity: 1,
        },
      ),
    ],
    ...others,
  })
}

export const sendEvent = (action: string, category: string, label?: string, value?: number) => {
  window.dataLayer.push({
    event: 'event',
    eventAction: action,
    eventCategory: category,
    eventLabel: label,
    eventValue: value,
  })
}

export const sendViewItem = (
  collectionId: string,
  title: string,
  price: number,
  categories?: ({ name?: string } | null)[],
) => sendEcommerce({ action: 'view_item', collectionId, title, price, categories })

export const sendPurchase = (
  transactionId: string,
  collectionId: string,
  title: string,
  price: number,
  categories?: ({ name?: string } | null)[],
) => sendEcommerce({ action: 'purchase', collectionId, title, price, categories, transactionId: transactionId })

export enum BookmarkGAEvent {
  Delete,
  Add,
  Select,
  Play,
  Edit,
}
