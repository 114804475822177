import React from 'react'
import { Box, Typography } from '@mui/material'
import theme from '@/theme'
import { RewardChip } from '@/components/atoms/Chips'

export interface RewardHeaderProps {
  rewardMessage: string
}

const RewardHeader = ({ rewardMessage }: RewardHeaderProps) => (
  <Box px={2} py={1.5} bgcolor={theme.palette.secondary.main} display="flex">
    <RewardChip />
    <Box ml={1}>
      <Typography>{rewardMessage}</Typography>
    </Box>
  </Box>
)

export default RewardHeader
