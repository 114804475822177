import React from 'react'
import { styled, Typography, TypographyProps } from '@mui/material'

interface HeadingH3Props extends TypographyProps {}

const StyledH3 = styled(Typography)({
  padding: '1.6rem 0',
})

const HeadingH3 = ({ children }: HeadingH3Props) => <StyledH3 variant="h3">{children}</StyledH3>

export default HeadingH3
