import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

interface BeginnerProps extends SvgIconProps {}

const Beginner = (props: BeginnerProps) => (
  <SvgIcon {...props}>
    <path d="M19.5,2.135a1,1,0,0,0-1,0L12,5.848h0L5.5,2.132A1,1,0,0,0,4,3V17a1,1,0,0,0,.5.868l7,4A1,1,0,0,0,12,22a1.057,1.057,0,0,0,.256-.033,1.032,1.032,0,0,0,.125-.052,1.029,1.029,0,0,0,.115-.047l0,0h0l0,0,6.992-4A1,1,0,0,0,20,17V3A1,1,0,0,0,19.5,2.135ZM6,16.42V4.723l6,3.428v11.7Z" />
  </SvgIcon>
)

export default Beginner
