import React from 'react'
import {
  Box,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  styled,
  Theme,
  useMediaQuery,
  Typography,
} from '@mui/material'
import { CenteringImg, DefaultButton } from '@/components/atoms'
import { useDayjs } from '@/libs/Dayjs'

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpoints.up('md')]: {
    width: '100%',
  },
}))

const StyledListItemAvatar = styled(ListItemAvatar)(({ theme }) => ({
  marginRight: '1.6rem',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

const ChildList = styled(List)(() => ({
  padding: 0,
  '& > *': {
    paddingLeft: 0,
    paddingRight: 0,
  },
}))

const ListItemMulti = styled(ListItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  padding: '1.6rem',
  [theme.breakpoints.up('md')]: {
    marginRight: '1.6rem',
    width: '36.2rem',
  },
}))
const ListItemSingle = styled(ListItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  padding: '1.6rem',
  [theme.breakpoints.up('md')]: {
    marginRight: '1.6rem',
    width: '48.8rem',
  },
}))

const StyledListItemText = styled(ListItemText)({
  marginTop: 0,
  '& .MuiListItem-root': {
    padding: 0,
  },
})

const LfTypography = styled(Typography)({
  whiteSpace: 'pre-line',
})

export interface ItemProps {
  campaign: Queries.audiobookDetailQuery['contentfulAudiobook']['campaignrequirementpurchase'][0]
  length: number
}

const Item = ({
  campaign: { slug, campaignTitle, shortDescription, headerImage, startDateTime, endDateTime },
  length,
}: ItemProps) => {
  const dayjs = useDayjs()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const ListItemComponent = length > 1 ? ListItemMulti : ListItemSingle

  return (
    length > 0 && (
      <ListItemComponent divider={isMobile}>
        <StyledBox>
          {headerImage && (
            <StyledListItemAvatar>
              <Link href={`/campaign/purchase/${slug}`}>
                <Box sx={{ display: { md: 'none', xs: 'block' } }}>
                  <CenteringImg width={64} height={45} coverArt={headerImage} title={campaignTitle} />
                </Box>
                <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                  <CenteringImg
                    width={length === 1 ? 146 : 108}
                    height={length === 1 ? 103 : 76}
                    coverArt={headerImage}
                    title={campaignTitle}
                  />
                </Box>
              </Link>
            </StyledListItemAvatar>
          )}
          <StyledListItemText>
            <ChildList>
              <ListItem
                sx={{ mb: 1 }}
                component={Link}
                variant="h2"
                color="textPrimary"
                href={`/campaign/purchase/${slug}`}
              >
                {campaignTitle}
              </ListItem>

              <ListItem>
                <LfTypography variant="body1" color="textSecondary">
                  {shortDescription?.shortDescription}
                </LfTypography>
              </ListItem>

              <ListItem sx={{ mt: 1.5 }}>
                <Box display="flex">
                  <Box width="4rem">
                    <Typography variant="body2" color="textPrimary">
                      期間:
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2" color="textSecondary">
                      {`${dayjs(startDateTime).format('YYYY/MM/DD')} - ${dayjs(endDateTime).format('YYYY/MM/DD')}`}
                    </Typography>
                  </Box>
                </Box>
              </ListItem>

              <ListItem sx={{ mt: 2, mb: 0 }}>
                <DefaultButton variant="contained" href={`/campaign/purchase/${slug}`} sx={{ fontWeight: 'bold' }}>
                  詳細を見る
                </DefaultButton>
              </ListItem>
            </ChildList>
          </StyledListItemText>
        </StyledBox>
      </ListItemComponent>
    )
  )
}

export default Item
