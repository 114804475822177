import React from 'react'
import { Link, LinkProps } from '@mui/material'
import { SearchFormHitText } from '@/components/atoms'

export interface SearchLinkProps extends LinkProps {
  searchWord: string
}

const SearchLink = ({ searchWord }: SearchLinkProps) => (
  <Link href={`/search?query=${encodeURIComponent(searchWord)}`}>
    <SearchFormHitText variant="body2" color="inherit">
      「{searchWord}」でもっと検索する
    </SearchFormHitText>
  </Link>
)

export default SearchLink
