import React from 'react'
import { Box, Step, StepLabel, Stepper } from '@mui/material'

export interface CampaignStepperProps {
  step: number // [0, 1, 2]
}

const CampaignStepper = ({ step }: CampaignStepperProps) => (
  <Box display="flex" justifyContent="center">
    <Stepper alternativeLabel activeStep={step}>
      {['必要事項入力', '入力内容確認', '応募完了'].map((label) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  </Box>
)

export default CampaignStepper
