import React from 'react'
import { Breadcrumbs as MuiBreadcrumbs, Link, LinkProps, Typography, TypographyProps, SxProps } from '@mui/material'
import { ChevronRightRounded } from '@mui/icons-material'

export interface BreadcrumbsProps {
  middleLabel?: React.ReactElement<LinkProps | TypographyProps>
  middleLabels?: React.ReactElement<LinkProps | TypographyProps>[]
  currentLabel: string
  sx?: SxProps
}

const Breadcrumbs = ({ middleLabel, middleLabels, currentLabel, sx }: BreadcrumbsProps) => (
  <>
    <MuiBreadcrumbs
      separator={<ChevronRightRounded fontSize="large" />}
      sx={{
        mx: { xs: 0, md: 2 },
        mt: 6,
        mb: 2,
        ...sx,
      }}
    >
      <Link color="textSecondary" href="/">
        ホーム
      </Link>
      {middleLabels}
      {middleLabel}
      <Typography variant="body1">{currentLabel}</Typography>
    </MuiBreadcrumbs>
  </>
)

export default Breadcrumbs
