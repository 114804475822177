import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'
import theme from '@/theme'

interface LineProps extends SvgIconProps {}

const Line = (props: LineProps) => (
  <SvgIcon
    sx={{
      fill: theme.palette.text.primary,
      width: '2.4rem',
      height: '2.4rem',
      '&.MuiSvgIcon-fontSizeLarge': {
        fill: theme.palette.text.secondary,
        width: '3.2rem',
        height: '3.2rem',
      },
    }}
    {...props}
  >
    <svg viewBox="0 0 24 24">
      <g transform="translate(-618 -601)">
        <path
          d="M18,7.3C18,3.276,13.963,0,9,0S0,3.276,0,7.3c0,3.61,3.2,6.634,7.527,7.206.293.063.692.193.793.444a1.847,1.847,0,0,1,.029.814s-.105.635-.128.77c-.039.227-.181.89.779.485A29.056,29.056,0,0,0,16.071,11.8h0A6.5,6.5,0,0,0,18,7.3ZM5.825,9.454a.175.175,0,0,1-.175.175H3.128a.174.174,0,0,1-.121-.049l0,0,0,0a.174.174,0,0,1-.049-.121h0V5.533a.175.175,0,0,1,.175-.175h.631a.175.175,0,0,1,.175.175V8.647H5.65a.175.175,0,0,1,.175.175Zm1.522,0a.175.175,0,0,1-.175.175H6.54a.175.175,0,0,1-.175-.175V5.533a.175.175,0,0,1,.175-.175h.631a.175.175,0,0,1,.175.175Zm4.345,0a.175.175,0,0,1-.175.175h-.631a.169.169,0,0,1-.045-.006h0l-.012,0-.005,0-.008,0-.008,0,0,0L10.788,9.6h0a.174.174,0,0,1-.044-.043l-1.8-2.43V9.454a.175.175,0,0,1-.176.175H8.136a.175.175,0,0,1-.175-.175V5.533a.175.175,0,0,1,.175-.175H8.8l.01,0,.006,0,.01,0,.006,0,.01,0,.006,0,.009.005.005,0,.009.006,0,0,.009.008,0,0,.011.011h0l.014.018,1.8,2.427V5.532a.175.175,0,0,1,.175-.175h.631a.175.175,0,0,1,.175.175V9.454Zm3.486-3.29A.175.175,0,0,1,15,6.339H13.287V7H15a.175.175,0,0,1,.175.176v.631A.175.175,0,0,1,15,7.984H13.287v.663H15a.175.175,0,0,1,.175.175v.631A.175.175,0,0,1,15,9.629H12.481a.174.174,0,0,1-.121-.049l0,0,0,0a.174.174,0,0,1-.049-.121h0V5.533h0a.174.174,0,0,1,.049-.121l0,0,0,0a.175.175,0,0,1,.122-.049H15a.175.175,0,0,1,.175.176v.631Z"
          transform="translate(621 605)"
        />
      </g>
    </svg>
  </SvgIcon>
)

export default Line
