import useSWR from 'swr'
import Audiobook from '@/libs/Audiobook'

export const useAudiobookInfo = (
  collectionId: string,
  filterByAvailable = false,
): Queries.audiobookInfoQuery['contentfulAudiobook'] => {
  const fetcher = async () => {
    const resp = await fetch(`/page-data/audiobook/_info/${collectionId}/page-data.json`)
    const json = await resp.json()
    return json.result.data.contentfulAudiobook
  }
  const data =
    useSWR(`AudiobookInfo_${collectionId}`, fetcher, {
      refreshInterval: 60 * 60 * 1000, // 1hごとのビルドに合わせて1時間毎にキャッシュをクリアする
    }).data ?? {}

  return !filterByAvailable || Audiobook.available(data)
    ? data
    : ({} as Queries.audiobookInfoQuery['contentfulAudiobook'])
}
