import React from 'react'
import Docomo from '@/components/SVG/Icons/Docomo'
import Au from '@/components/SVG/Icons/Au'
import SoftBank from '@/components/SVG/Icons/SoftBank'
import { ButtonProps } from '@mui/material'
import DefaultButton from '@/components/atoms/DefaultButton'

export interface CarrierSigninButtonProps extends ButtonProps {
  carrier: 'docomo' | 'au' | 'softbank'
}

const CarrierSigninButton = ({ carrier, children, ...rest }: CarrierSigninButtonProps) => {
  const Icon = () => {
    switch (carrier) {
      case 'docomo':
        return <Docomo />
      case 'au':
        return <Au />
      case 'softbank':
        return <SoftBank />
    }
  }

  return (
    <DefaultButton startIcon={<Icon />} variant="outlined" size="large" sx={{ mb: 3 }} {...rest}>
      {children}
    </DefaultButton>
  )
}

export default CarrierSigninButton
