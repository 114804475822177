import React, { ReactNode } from 'react'
import { LinkProps } from '@mui/material'
import { BreadcrumbsProps } from '.'
import { Script } from 'gatsby'

const extractTextFromNode = (node: ReactNode): string => {
  const children = React.Children.toArray(node)

  return children.reduce<string>((m, n) => {
    if (React.isValidElement(n)) {
      return m + (n.props.children ? extractTextFromNode(n.props.children) : '')
    } else {
      switch (typeof n) {
        case 'string':
        case 'number':
          return m + n.toString()
      }
    }
    return m
  }, '')
}

export const BreadcrumbsJsonLd = ({ middleLabel, middleLabels, currentLabel }: BreadcrumbsProps) => {
  return (_?: Queries.SiteSiteMetadata) => ({
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'ホーム',
        item: 'https://listengo.dwango.jp/',
      } as Record<string, unknown>,
    ]
      .concat(
        middleLabel
          ? [
              {
                '@type': 'ListItem',
                position: 2,
                name: extractTextFromNode(middleLabel),
                item: `https://listengo.dwango.jp${(middleLabel as React.ReactElement<LinkProps>).props?.href}`,
              },
            ]
          : [],
      )
      .concat(
        middleLabels
          ? middleLabels.map((label, index) => ({
              '@type': 'ListItem',
              position: index + 2,
              name: extractTextFromNode(label),
              item: `https://listengo.dwango.jp${(label as React.ReactElement<LinkProps>).props?.href}`,
            }))
          : [],
      )
      .concat([
        {
          '@type': 'ListItem',
          position: middleLabel ? 3 : middleLabels ? middleLabels.length + 2 : 2,
          name: currentLabel,
        },
      ]),
  })
}

const BreadcrumbsHead = (props: BreadcrumbsProps) => (
  <Script type="application/ld+json">{JSON.stringify(BreadcrumbsJsonLd(props)())}</Script>
)

export default BreadcrumbsHead
