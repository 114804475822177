import React from 'react'
import { Button, Modal, Paper, Slide, styled } from '@mui/material'

const StyledPaper = styled(Paper)({
  position: 'fixed',
  bottom: 0,
  width: '100%',
  borderRadius: '4px 4px 0 0',
  outline: 0,
})

const CancelButton = styled(Button)({
  position: 'absolute',
  top: '-3.5rem',
  right: '1rem',
  color: '#ffffffde',
  fontWeight: 'bold',
})

export interface ConfirmProps {
  open: boolean
  onClose: () => void
  children: React.ReactNode
}

const Confirm = ({ open, onClose, children }: ConfirmProps) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Slide direction="up" in={open} unmountOnExit>
        <StyledPaper>
          <CancelButton size="small" onClick={onClose}>
            キャンセル
          </CancelButton>
          {children}
        </StyledPaper>
      </Slide>
    </Modal>
  )
}

export default Confirm
