/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { createContext, Fragment, ReactNode, useContext, useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { WindowLocation } from '@reach/router'
import { navigate } from 'gatsby'
import Cookie from 'js-cookie'
import dayjs from 'dayjs'
import queryString from 'query-string'
import Fetch, { FetchResponse } from '@/libs/Fetch'
import useSWR, { Fetcher, SWRConfiguration, useSWRConfig } from 'swr'
import camelcaseKeys from 'camelcase-keys'
import { env } from '@/libs/Env'
import { identity, isArray, pickBy } from 'lodash'
import { useHydrated } from 'react-hydration-provider'

const baseUrl = process.env.API_BASE_URL
const site = 'listengo,dwango_jp_android,dwango_jp_ios'
const clientId = 'listengo.dwango.jp'
const loginRequired: string[] = ['/mypage/', '/purchase/history/', '/audiobook/[0-9]+/player', '/favorite/']

const ConnectContext = createContext<ConnectContextProps>(undefined)

const IdKey = `id-${env}`
export const AccessTokenKey = `access_token-${env}`

export const ConnectProvider = ({ path, location, children }: ConnectProviderProps) => {
  const { cache, mutate } = useSWRConfig()
  const fetchErrorHandler = (name: string) => {
    if (name === 'AbortError') {
      throwError({ code: 408, message: 'request_timeout' })
    }
  }

  const fetch = new Fetch(baseUrl, {
    snakeCaseKeysData: true,
    errorHandler: fetchErrorHandler,
  })

  const login = async (email: string, password: string) => {
    Cookie.remove(AccessTokenKey)
    return fetch.post('/asp/login', { credentials: 'include', params: { clientId, email, password } })
  }

  const logout = async () => {
    await fetch.get('/asp/logout', { credentials: 'include' })
    Cookie.remove(IdKey)
    Cookie.remove(AccessTokenKey)
    Cookie.remove('spa-cpwebto')
    Cookie.remove('spa-session-id')

    // REF https://github.com/dwangojp/dwjp--listengo.dwango.jp--frontend/pull/1529
    setTimeout(() => {
      mutateAll()
    }, 1)
  }

  const signin = (carrier: string, redirectPath: string) => {
    Cookie.remove(AccessTokenKey)
    const redirectUri = location.origin + redirectPath
    window.location.href = `${baseUrl}/asp/signin/${carrier}?client_id=${clientId}&redirect_uri=${redirectUri}`
  }

  const reCaptchaSiteverify = async (response: string, action: string) => {
    return fetch.get('/recaptcha/api/siteverify', { params: { response, action } })
  }

  const content = async (resourceId: string, contentId?: string, accessToken = true, contentIdType = 'DAM') => {
    const params = { contentId, contentIdType, access_token: accessToken ? await getAccessToken() : '' }
    const query = new URLSearchParams(params)
    return `${baseUrl}/api/content/${resourceId}/signed-url?${query}`
  }

  const user = {
    post: async (email: string, password: string) =>
      fetch.post(`/api/user`, {
        params: { email, password },
      }),
    verify: {
      post: async () =>
        fetch.post('/api/user.verify', {
          params: {
            accessToken: await getAccessToken(),
          },
        }),
      put: async (accessToken: string) =>
        fetch.put('/api/user.verify', {
          params: {
            accessToken,
          },
        }),
    },
  }

  const bill = (() => {
    // snakeCaseKeysDataでpostパラメータが壊れて失敗するため変換無しで利用している。
    const fetch = new Fetch(baseUrl, { errorHandler: fetchErrorHandler })
    return {
      post: async (contentId: string, contentIdType = 'DAM') =>
        fetch.post('/api/bill', {
          params: {
            contentId,
            contentIdType,
            access_token: await getAccessToken(),
          },
        }),
      pay: {
        post: async (billId: string) =>
          fetch.post('/api/bill.pay', {
            params: {
              billId,
              access_token: await getAccessToken(),
            },
          }),
      },
    }
  })()

  const coupons = {
    get: async (couponId: string) => fetch.get(`/coupons/${couponId}`, {}),
    code: {
      isValid: {
        get: async (couponId: string, code: string) =>
          fetch.get('/coupons/code/isvalid', {
            params: {
              accessToken: await getAccessToken(),
              couponId,
              code,
            },
          }),
      },
      bill: {
        post: async (contentId: string, couponId: string, code: string, contentIdType = 'DAM') =>
          fetch.post('/coupons/code/bill', {
            params: {
              collectionId: contentId,
              contentType: contentIdType,
              access_token: await getAccessToken(),
              couponId,
              code,
            },
          }),
      },
    },
  }

  const campaign = {
    purchase: {
      get: async (slug: string): Promise<FetchResponse<CampaignPurcharse>> =>
        fetch.get(`/api/campaign/purchase/${slug}`, {
          params: {
            access_token: await getAccessToken(),
          },
        }),
      useGet: (slug: string): { data: CampaignPurcharse; error: Error } => {
        const { data, error } = useSWR<CampaignPurcharse, Error>(
          hasCookie() ? `/api/campaign/purchase/${slug}` : null,
          async () => {
            const response = await campaign.purchase.get(slug)
            if (response.status !== 200) {
              throw new Error(`${response.status}`)
            }
            return response.data
          },
          { dedupingInterval: 60 * 1000, errorRetryInterval: 60 * 1000 },
        )
        return { data, error }
      },
      mutateGet: (slug: string): Promise<CampaignPurcharse> => {
        return mutate(`/api/campaign/purchase/${slug}`)
      },
      post: async (slug: string, inputs: CampaignFormInputs): Promise<FetchResponse<SendCampaignMail>> =>
        fetch.post(`/api/campaign/purchase/${slug}`, {
          params: {
            access_token: await getAccessToken(),
            ...inputs,
          },
        }),
    },
  }
  const favorite = {
    post: async (collectionId: string) => {
      return fetch.post('/api/favorite', {
        params: {
          collectionId,
          access_token: await getAccessToken(),
        },
      })
    },
    delete: async (collectionId: string) => {
      return fetch.delete('/api/favorite', {
        params: {
          collectionId,
          access_token: await getAccessToken(),
        },
      })
    },
  }
  const bookmark = {
    post: async (collectionId: string, title: string, memo: string, chapter: number, currentTime: number) => {
      return fetch.post('/api/bookmark', {
        params: {
          collectionId,
          title,
          memo,
          chapter,
          currentTime,
          access_token: await getAccessToken(),
        },
      })
    },
    put: async (
      id: number,
      collectionId: string,
      title: string,
      memo: string,
      chapter: number,
      currentTime: number,
    ) => {
      return fetch.put(`/api/bookmark/${id}`, {
        params: {
          collectionId,
          title,
          memo,
          chapter,
          currentTime,
          access_token: await getAccessToken(),
        },
      })
    },
    delete: async (id: number, collectionId: string) => {
      return fetch.delete(`/api/bookmark/${id}`, {
        params: {
          collectionId,
          access_token: await getAccessToken(),
        },
      })
    },
    mutate: (collectionId: string) => {
      mutate(['bookmark', collectionId])
    },
  }

  const fetchers = {
    userinfo: async () =>
      fetch.get('/api/userinfo', {
        params: { site, accessToken: await getAccessToken() },
      }),
    pointItemized: async (_key?: string, startDate?: string, endDate?: string) =>
      fetch.get('/api/pointItemized', {
        params: {
          accessToken: await getAccessToken(),
          start_date: startDate,
          end_date: endDate,
        },
      }),
    billItemized: async (
      _key?: string | [string, { collectionIds?: string[]; itemsPerPage: number; startIndex: number }],
    ) => {
      const params = isArray(_key) ? _key[1] : undefined
      return fetch.get('/api/billItemized', {
        params: pickBy(
          {
            accessToken: await getAccessToken(),
            ...params,
            contentIds:
              params?.collectionIds && JSON.stringify(params?.collectionIds?.map((id) => ({ type: 'DAM', id }))),
          },
          identity,
        ),
      })
    },
    favorite: async (
      _key?: string | [string, { collectionIds?: string[]; itemsPerPage: number; startIndex: number }],
    ) => {
      const params = isArray(_key) ? _key[1] : undefined
      return fetch.get('/api/favorite', {
        params: pickBy(
          {
            accessToken: await getAccessToken(),
            ...params,
            collectionIds: params?.collectionIds && JSON.stringify(params?.collectionIds),
          },
          identity,
        ),
      })
    },
    bookmark: async (_key?: [string, string]) => {
      const collectionId = isArray(_key) ? _key[1] : ''
      return fetch.get('/api/bookmark', {
        params: {
          accessToken: await getAccessToken(),
          collectionId,
        },
      })
    },
  }

  const [isClient, setIsClient] = useState(false)
  useEffect(() => {
    setIsClient(true)
  }, [])

  const hydrated = useHydrated()
  //画面更新したい時に使う、true/falseに意味はない
  const [, setRerender] = useState(false)
  const rerender = () => setRerender((prevState) => !prevState)

  const hasCookie = (): boolean => {
    return hydrated && Cookie.get('id') && Cookie.get(IdKey) ? true : false
  }

  const saveCookie = () => {
    if (IdKey !== 'id') {
      Cookie.set(IdKey, Cookie.get('id'))
    }
  }

  const redirect = (): void => {
    navigate(getRedirectPath())
  }

  const getRedirectPath = (): string => {
    return location.state?.redirectPath ?? '/'
  }

  const throwError = (state?: ErrorProps): void => {
    if (!path.includes('/error')) {
      navigate('/error', {
        state,
      })
    }
  }

  const mutateAll = (): void => {
    Array.from(cache.keys()).map((key) => mutate(key))
  }

  const getAccessToken = async (): Promise<string> => {
    if (Cookie.get(AccessTokenKey)) {
      return Cookie.get(AccessTokenKey)
    }

    const hash: string = await new Promise((resolve) => {
      const authUri = `${baseUrl}/asp/auth?client_id=${clientId}&redirect_uri=${location.origin}/logos/logo-48.png`
      const onLoad = ({ currentTarget }: React.SyntheticEvent<HTMLIFrameElement, Event>) => {
        resolve(currentTarget.contentWindow.location.hash.substring(1))
      }

      const newDiv = document.createElement('div')
      document.body.append(newDiv)
      const root = createRoot(newDiv)
      root.render(<iframe style={{ display: 'none' }} src={authUri} onLoad={onLoad} />)
    })

    const { accessToken, expiresIn, error } = camelcaseKeys(queryString.parse(hash)) as Record<string, string>
    window.location.hash = ''

    if (error === 'unauthorized_user') {
      await logout()
      Cookie.remove('id', { domain: 'dwango.jp' })
      navigate('/signin', {
        state: {
          redirectPath: path,
        },
      })
    }

    if (error) {
      throwError()
    }
    if (!accessToken) {
      return ''
    }
    Cookie.set(AccessTokenKey, accessToken, {
      expires: new Date(dayjs().add(Number(expiresIn), 'second').format()),
    })
    return accessToken
  }

  const haveToLogin = (): boolean => {
    if (!hydrated || hasCookie()) {
      return false
    }
    const paths = loginRequired.join('|')

    return new RegExp(`^${paths}`).test(path)
  }

  useEffect(() => {
    if (haveToLogin()) {
      navigate('/signin', {
        state: {
          redirectPath: path,
        },
      })
    }
  }, [path, hydrated])

  return (
    <ConnectContext.Provider
      value={{
        login,
        logout,
        signin,
        reCaptchaSiteverify,
        content,
        user,
        bill,
        coupons,
        campaign,
        favorite,
        bookmark,
        fetchers,
        isClient,
        rerender,
        hasCookie,
        saveCookie,
        redirect,
        throwError,
        mutateAll,
        getAccessToken,
      }}
    >
      <Fragment key={isClient.toString()}> {!haveToLogin() && children}</Fragment>
    </ConnectContext.Provider>
  )
}

export const useConnect = () => useContext(ConnectContext)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useConnectStates = (stateNames: (ConnectStateName | any[])[], config?: SWRConfigProps): ConnectStates => {
  const { hasCookie, fetchers, throwError } = useConnect()
  const states = {} as ConnectStates
  stateNames.forEach((name) => {
    const key: ConnectStateName = isArray(name) ? name[0] : name
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { data, error } = useSWR(hasCookie() ? name : null, fetchers[key] as any, {
      dedupingInterval: 60 * 1000,
      ...config,
    })
    if (error) {
      throwError()
    }
    states[key] = data?.data
  })

  return states
}

//fetch data
export type Userinfo = {
  address: { country: string; region: string }
  birthday: string
  email: boolean
  emailVerified: boolean
  gender: string
  hasPoint: number
  locale: string
  nickname: string
  picture: string
  siteAvailable: boolean
  siteSubscription: string[]
  type: string
  updateTime: string
  userId: string
  walletAvailable: boolean
  walletMessage: string
}
type PointItem = {
  processedType: number
  beforeTotalPoint: number
  afterTotalPoint: number
  processedPoint: number
  processedDate: string
  options: {
    courseId: number
    paymentTypeId: number
    addReason: number
  }
}
type PointItemized = {
  list: PointItem[]
}
export type BillItem = {
  id: string
  contentIdType: string
  contentId: string
  billId: string
  displayName: string
  paid: string
  price: string
  quantity: string
  site: string
  status: string
  thumbnailUrl: string
  updated: string
}
export type BillItemized = {
  totalResults: number
  list: BillItem[]
}
type CouponsAudiobook = {
  contentType: string
  collectionId: string
  price: number
}
export type CouponsGetCouponId = {
  couponId: string
  isOverLimit: boolean
  startDateTime: string
  endDateTime: string
  audiobooks: CouponsAudiobook[]
}
export type CampaignPurchase = {
  slug: string
  startDateTime: string
  endDateTime: string
}

export type FavoriteItem = {
  collectionId: string
  favoriteDate: string
}
export type Favorite = {
  totalResults: number
  list: FavoriteItem[]
}

export type BookmarkItem = {
  id: number
  collectionId: string
  bookmarkDate: string
  title: string
  memo: string
  chapter: number
  currentTime: number
}
export type Bookmark = {
  totalResults: number
  list: BookmarkItem[]
}

type CouponsGetIsValid = {
  couponId: string
  isValid: boolean
}

type CampaignPurcharse =
  | {
      slug: string
      startDateTime: string
      endDateTime: string
    }
  | {
      code: number
      message: string
    }
type SendCampaignMail = {
  id: string
}
export interface ErrorProps {
  code?: number
  message?: string
}

export type CampaignFormInputs = {
  email?: string
  name?: string
  postalCode?: string
  addressLevel1?: string
  addressLine1?: string
  tel?: string
  message?: string
}

//util
type SWRConfigProps = SWRConfiguration<FetchResponse, Error, Fetcher<FetchResponse>>
//props
type ConnectStates = {
  userinfo: Userinfo
  pointItemized: PointItemized
  billItemized: BillItemized
  favorite: Favorite
  bookmark: Bookmark
}
type ConnectStateName = keyof ConnectStates
type ConnectStateFetchers = {
  userinfo: () => Promise<FetchResponse<Userinfo>>
  pointItemized: (_key?: string, startDate?: string, endDate?: string) => Promise<FetchResponse<PointItemized>>
  billItemized: (
    _key?: string | [string, { collectionIds?: string[]; itemsPerPage: number; startIndex: number }],
  ) => Promise<FetchResponse<BillItemized>>
  favorite: (
    _key?: string | [string, { collectionIds?: string[]; itemsPerPage: number; startIndex: number }],
  ) => Promise<FetchResponse<Favorite>>
  bookmark: (_key?: [string, string]) => Promise<FetchResponse<Bookmark>>
}

type ConnectContextProps = {
  login: (email: string, password: string) => Promise<FetchResponse>
  logout: () => Promise<void>
  signin: (carrier: 'docomo' | 'au' | 'softbank' | 'eznumber', redirectPath: string) => void
  reCaptchaSiteverify: (response: string, action: string) => Promise<FetchResponse>

  content: (resourceId: string, contentId?: string, accessToken?: boolean, contentIdType?: string) => Promise<string>
  user: {
    post: (email: string, password: string) => Promise<FetchResponse>
    verify: {
      post: () => Promise<FetchResponse>
      put: (accessToken: string) => Promise<FetchResponse>
    }
  }
  bill: {
    post: (contentId: string, contentIdType?: string) => Promise<FetchResponse>
    pay: { post: (billId: string) => Promise<FetchResponse> }
  }
  coupons: {
    get: (couponId: string) => Promise<FetchResponse<CouponsGetCouponId>>
    code: {
      isValid: {
        get: (couponId: string, code: string) => Promise<FetchResponse<CouponsGetIsValid>>
      }
      bill: {
        post: (contentId: string, couponId: string, code: string, contentIdType?: string) => Promise<FetchResponse>
      }
    }
  }
  campaign: {
    purchase: {
      get: (slug: string) => Promise<FetchResponse<CampaignPurcharse>>
      useGet: (slug: string) => { data: CampaignPurcharse; error: Error }
      mutateGet: (slug: string) => Promise<CampaignPurcharse>
      post: (slug: string, inputs: CampaignFormInputs) => Promise<FetchResponse<SendCampaignMail>>
    }
  }
  favorite: {
    post: (collectionId: string) => Promise<FetchResponse>
    delete: (collectionId: string) => Promise<FetchResponse>
  }
  bookmark: {
    post: (
      collectionId: string,
      title: string,
      memo: string,
      chapter: number,
      currentTime: number,
    ) => Promise<FetchResponse>
    put: (
      id: number,
      collectionId: string,
      title: string,
      memo: string,
      chapter: number,
      currentTime: number,
    ) => Promise<FetchResponse>
    delete: (id: number, collectionId: string) => Promise<FetchResponse>
    mutate: (collectionId: string) => void
  }
  fetchers: ConnectStateFetchers

  isClient: boolean
  rerender: () => void
  hasCookie: () => boolean
  saveCookie: () => void
  redirect: () => void
  throwError: (state?: ErrorProps) => void
  mutateAll: () => void
  getAccessToken: () => Promise<string>
}

type ConnectProviderProps = {
  path: string
  location: WindowLocation & {
    state?: {
      redirectPath?: string
    }
  }
  children: ReactNode
}
