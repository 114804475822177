import React, { useCallback, useState } from 'react'
import { RepeatOneRounded, RepeatRounded } from '@mui/icons-material'
import IconCaptionButton from '@/components/atoms/Audiobook/Player/IconCaptionButton'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { SvgIconProps, SvgIconTypeMap } from '@mui/material'
import { animated, useSpring } from 'react-spring'
import { useAudio } from '@/contexts/audio'

export enum REPEAT_STATE {
  OFF,
  ON,
  ONE,
}

type ButtonState = {
  Icon: OverridableComponent<SvgIconTypeMap<SvgIconProps, 'svg'>>
  color: 'default' | 'primary'
}

interface RepeatProps {}

const Repeat = (_: RepeatProps) => {
  const { useRepeat, useAudioDispach } = useAudio()
  const repeat = useRepeat()
  const { setRepeat } = useAudioDispach()
  const buttonStates: ButtonState[] = [
    {
      Icon: RepeatRounded,
      color: 'default',
    },
    {
      Icon: RepeatRounded,
      color: 'primary',
    },
    {
      Icon: RepeatOneRounded,
      color: 'primary',
    },
  ]
  const [{ Icon, color }, setButtonState] = useState(buttonStates[repeat])
  const [clicked, setClicked] = useState(false)

  const handleClick = () => {
    setRepeat(repeat === REPEAT_STATE.ONE ? REPEAT_STATE.OFF : repeat + 1)
    setClicked(true)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const animation = async (next: any) => {
    if (!clicked) {
      return
    }

    const degs =
      repeat === REPEAT_STATE.OFF
        ? [
            { from: 0, to: -90 },
            { from: 90, to: 0 },
          ]
        : [
            { from: 0, to: 90 },
            { from: -90, to: 0 },
          ]
    const duration = 100

    await next({
      reset: true,
      from: { transform: `rotate(${degs[0].from}deg)` },
      transform: `rotate(${degs[0].to}deg)`,
      config: { duration },
    })
    setButtonState(buttonStates[repeat])
    await next({
      reset: true,
      from: { transform: `rotate(${degs[1].from}deg)` },
      transform: `rotate(${degs[1].to}deg)`,
      config: { duration },
    })
    setClicked(false)
  }

  const iconStyle = useSpring({
    // eslint-disable-next-line react-hooks/exhaustive-deps
    to: useCallback(animation, [clicked]),
  })

  return (
    <IconCaptionButton color={color} caption="リピート" onClick={handleClick}>
      <animated.div style={{ ...iconStyle, height: '2.4rem' }}>
        <Icon />
      </animated.div>
    </IconCaptionButton>
  )
}

export default Repeat
