import dayjs from 'dayjs'

export interface History {
  pathname: string
  expires: string
}

export const setHistory = (histories: History[]) => {
  localStorage.setItem('detail_history', JSON.stringify(histories))
}

export const deletePathname = (pathname: string) => {
  const histories = getHistories().filter((h) => !h.pathname.includes(pathname))
  const now = dayjs()
  setHistory(histories.filter((h) => now.isBefore(dayjs(h.expires))))
}

export const resetHistory = () => {
  const now = dayjs()
  const histories = getHistories()
  setHistory(histories.filter((h) => now.isBefore(dayjs(h.expires))))
}

export const putPathname = (pathname?: string) => {
  deletePathname(pathname)
  const histories = getHistories()
  histories.push({
    pathname,
    expires: dayjs().add(7, 'day').format(),
  })
  if (histories.length > 20) {
    histories.shift()
  }
  setHistory(histories)
}

export const getHistories = () => JSON.parse(localStorage.getItem('detail_history') || '[]') as History[]
