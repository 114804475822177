import React, { FormEvent, useRef } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useConnectStates } from '@/contexts/connect'
import {
  Box,
  Button,
  IconButton,
  Link,
  List,
  ListItem as MuiListItem,
  styled,
  Theme,
  useMediaQuery,
  Typography,
  SxProps,
} from '@mui/material'
import { DeleteRounded, PlayArrowRounded, PauseRounded } from '@mui/icons-material'
import { PlayUnavailable } from '@/components/SVG/Icons'
import {
  CenteringImg,
  UppingImg,
  GoogleTagManagerEvent,
  DuringAuditionImg,
  DuringAuditionUppingImg,
  FavoriteIconButton,
  DefaultButton,
} from '@/components/atoms'
import { ListItemAvatar, ListItem, ListWrap } from '@/components/atoms/Audiobook'
import { searchInsightClient, INSIGHT_METHOD_NAME } from '@/libs/Algolia'
import { RewardChip, NewChip, DiscountChip } from '@/components/atoms/Chips'
import Audiobook, { findDiscount } from '@/libs/Audiobook'
import { useAudition } from '@/contexts/audition'
import AuditionPlayer, { AuditionPlayerHandles } from '@/components/molecules/AuditionPlayer'
import { ItemFavoriteProps } from '@/contexts/favorite'
import { AuditionPlayerProvider, useAuditionPlayer } from '@/contexts/auditionPlayer'
import theme from '@/theme'

dayjs.extend(utc)

// 通常の視聴ボタンはカスタムデザインを適用
const auditionButtonSx: SxProps = {
  minWidth: { xs: '8rem', md: '11rem' },
  maxWidth: { xs: '8rem', md: 'none' },
  mb: { xs: 1, md: 0 },
  padding: '6px 8px',
  width: { md: '100%' },
  height: '2.4rem',
  mt: 1.5,
  fontSize: '1rem',
  fontWeight: 'bold',
  '& svg': {
    '&:nth-of-type(1)': {
      fontSize: '1.6rem',
    },
  },
  '& > .MuiButton-startIcon': {
    ml: 0,
    mr: 0.5,
  },
  backgroundColor: theme.palette.secondary.dark,
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
  },
  ['@media(pointer: fine)']: {
    '&:hover': {
      backgroundColor: '#7c631d',
    },
  },
}

const ChildList = styled(List)(({ theme }) => ({
  padding: 0,
  '& > *': {
    paddingLeft: 0,
    paddingRight: 0,
  },
  [theme.breakpoints.up('md')]: {
    width: '100%',
    marginTop: '1.6rem',
  },
}))

const StrikePriceBox = styled(Box)({
  color: 'rgba(255,255,255,0.7)',
  marginRight: '4px',
  position: 'relative',
  '&:before': {
    position: 'absolute',
    content: '""',
    display: 'block',
    transform: 'rotate(-20deg)',
    backgroundColor: 'rgba(255,255,255,0.7)',
    width: '100%',
    height: '1px',
    top: '50%',
    left: '0',
  },
})

const StyledListItemIcon = styled(MuiListItem)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '1.6rem',
  marginBottom: '0',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    padding: '0 1.6rem 0',
  },
  [theme.breakpoints.down('md')]: {
    padding: '0',
  },
}))

const NoCoverArtBox = styled(Box)(({ theme }) => ({
  display: 'block',
  width: '8rem',
  height: '13rem',
  backgroundColor: 'transparent',

  [theme.breakpoints.up('md')]: {
    width: '100%',
    height: '23.6rem',
  },
}))

export interface ItemHistoryProps {
  onDeleteBtnClick?: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

export interface ItemRewardProps {
  btnDisabled?: boolean
  paymentHandler?: (collectionId: string) => void
}

export type ItemProps = Pick<Queries.audiobookInfoQuery['contentfulAudiobook'], 'collectionId'> &
  Partial<Omit<Queries.audiobookInfoQuery['contentfulAudiobook'], 'collectionId'>> & {
    onDeleteBtnClick?: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void
    queryID?: string
    position?: number
    type?: 'default' | 'history' | 'favorite'
    reward?: ItemRewardProps
    favorite?: ItemFavoriteProps
  }

const Item = ({
  collectionId,
  coverArt,
  title,
  authors,
  narrators,
  duration,
  retailPrice,
  onDeleteBtnClick,
  queryID,
  position,
  startDateTime,
  importedDateTime,
  campaignrequirementpurchase,
  discount,
  sampleFile,
  reward,
  downloadable,
  favorite,
  type = 'default',
}: ItemProps) => {
  const { useCollectionId } = useAudition()
  const { usePaused, useLoading } = useAuditionPlayer()
  const currentCollectionId = useCollectionId()
  const paused = usePaused()
  const loading = useLoading()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const { userinfo } = useConnectStates(['userinfo'])
  const auditionRef = useRef<AuditionPlayerHandles>()
  const detailUrl = `/audiobook/${collectionId}${queryID ? `?queryId=${queryID}` : ''}`

  const discountTerm = findDiscount(discount)
  const clickOptions = {
    queryID: queryID,
    objectIDs: [collectionId],
    positions: [position],
    index: process.env.ALGOLIA_INDEX_NAME,
    eventName: 'click result page',
    userToken: userinfo?.userId,
  }

  const Audition =
    !paused && currentCollectionId === collectionId ? (
      loading ? (
        <DefaultButton variant="contained" disabled sx={auditionButtonSx}>
          読み込み中
        </DefaultButton>
      ) : (
        <Button
          sx={auditionButtonSx}
          variant="contained"
          startIcon={<PauseRounded fontSize="large" />}
          onClick={() => {
            auditionRef.current?.pause()
          }}
        >
          一時停止
        </Button>
      )
    ) : (
      <GoogleTagManagerEvent action="trial_listening" category={title}>
        <Button
          sx={auditionButtonSx}
          variant="contained"
          startIcon={<PlayArrowRounded />}
          onClick={() => {
            auditionRef.current?.play()
          }}
          disabled={currentCollectionId === collectionId && loading}
        >
          試聴する
        </Button>
      </GoogleTagManagerEvent>
    )

  const NotAudition = (
    <DefaultButton variant="contained" sx={auditionButtonSx} startIcon={<PlayUnavailable />} disabled>
      試聴不可
    </DefaultButton>
  )

  const onClickHandler = (event: FormEvent) => {
    queryID && searchInsightClient(INSIGHT_METHOD_NAME.clickedObjectIDsAfterSearch, clickOptions)
    if (reward?.paymentHandler) {
      event.preventDefault()
      reward?.paymentHandler(collectionId)
    }
  }

  return (
    <>
      <AuditionPlayer
        ref={auditionRef}
        audiobook={{
          collectionId,
          title,
          coverArt,
          authors,
          sampleFile,
        }}
      />
      <ListWrap dense={currentCollectionId === collectionId && !loading}>
        <ListItemAvatar>
          {coverArt ? (
            <Link width="100%" href={detailUrl} onClick={onClickHandler}>
              {isMobile ? (
                !paused && currentCollectionId === collectionId && !loading ? (
                  <DuringAuditionUppingImg width={80} coverArt={coverArt} title={title} />
                ) : (
                  <UppingImg width={80} coverArt={coverArt} title={title} />
                )
              ) : !paused && currentCollectionId === collectionId && !loading ? (
                <DuringAuditionImg
                  width="100%"
                  height={236}
                  artWidth={204}
                  artHeight={204}
                  coverArt={coverArt}
                  title={title}
                />
              ) : (
                <Box height={236} py={2} display="flex" justifyContent="center">
                  <CenteringImg width={204} height={204} coverArt={coverArt} title={title} />
                </Box>
              )}
            </Link>
          ) : (
            <NoCoverArtBox />
          )}
          {isMobile && (sampleFile?.resource_id ? Audition : NotAudition)}
        </ListItemAvatar>

        <Box width="100%">
          <Box width={{ md: '100%' }} px={{ md: 2 }}>
            {!isMobile && (
              <Box width="100%" textAlign="center">
                {sampleFile?.resource_id ? Audition : NotAudition}
              </Box>
            )}
            <ChildList>
              {Audiobook.hasRewardChip({ campaignrequirementpurchase }) ? (
                <Box pb={0.5}>
                  <RewardChip />
                </Box>
              ) : discountTerm ? (
                <Box pb={0.5}>
                  <DiscountChip discountLabel={discountTerm.label} />
                </Box>
              ) : Audiobook.hasNewChip({ startDateTime, importedDateTime }) ? (
                <Box pb={0.5}>
                  <NewChip />
                </Box>
              ) : null}
              {title && (
                <MuiListItem
                  sx={{
                    mb: 1,
                    p: 0,
                    lineHeight: 1.5,
                  }}
                  component={Link}
                  variant="h2"
                  color="textPrimary"
                  href={detailUrl}
                  onClick={onClickHandler}
                >
                  <Typography variant="h2" component="h2">
                    {title}
                  </Typography>
                </MuiListItem>
              )}

              {authors?.length > 0 && (
                <ListItem
                  primary="著者:"
                  secondary={authors
                    .map((author) => author.names.find((name) => name.languageAndScriptCode === 'ja-Hani').text)
                    .join(' ')}
                />
              )}

              {narrators?.length > 0 && (
                <ListItem
                  primary="ナレーター:"
                  secondary={
                    narrators &&
                    narrators
                      .map((narrator) => narrator.names.find((name) => name.languageAndScriptCode === 'ja-Hani').text)
                      .join(' ')
                  }
                />
              )}
              <ListItem primary="再生時間:" secondary={dayjs.utc(Number(duration)).format('HH:mm:ss')} />
              {downloadable && <ListItem primary="ダウンロード:" secondary={'可能'} />}

              {['default', 'favorite'].includes(type) && (
                <MuiListItem sx={{ mt: 1, px: 0, pb: 0 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <Box>
                      <Button
                        variant="contained"
                        color="primary"
                        href={detailUrl}
                        onClick={onClickHandler}
                        disabled={reward?.btnDisabled}
                      >
                        {reward ? (
                          <Typography variant="h4" color="inherit">
                            クーポンで購入
                          </Typography>
                        ) : discountTerm ? (
                          <>
                            <StrikePriceBox>
                              <Typography variant="body2" color="inherit">
                                {retailPrice.toLocaleString()}pt
                              </Typography>
                            </StrikePriceBox>
                            <Typography variant="h4" color="inherit">
                              {discountTerm.price.toLocaleString()}pt
                            </Typography>
                          </>
                        ) : (
                          <Typography variant="h4" color="inherit">
                            {retailPrice?.toLocaleString()}pt
                          </Typography>
                        )}
                      </Button>
                    </Box>
                    {type === 'default' && favorite && (
                      <Box>
                        <FavoriteIconButton
                          isFavorite={favorite.isFavorite}
                          onClick={() => favorite.onClick(collectionId, title)}
                        />
                      </Box>
                    )}
                    {type === 'favorite' && favorite && (
                      <Box>
                        <IconButton
                          onClick={() => {
                            favorite.onClick(collectionId, title)
                          }}
                        >
                          <DeleteRounded fontSize="large" />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                </MuiListItem>
              )}
            </ChildList>
          </Box>
          {type === 'history' && (
            <StyledListItemIcon>
              <IconButton
                onClick={() => {
                  stop()
                  onDeleteBtnClick()
                }}
              >
                <DeleteRounded fontSize="large" />
              </IconButton>
            </StyledListItemIcon>
          )}
        </Box>
      </ListWrap>
    </>
  )
}

const ItemWithAuditionPlayer = (props: ItemProps) => (
  <AuditionPlayerProvider>
    <Item {...props} />
  </AuditionPlayerProvider>
)
export default ItemWithAuditionPlayer
