/* eslint-disable @typescript-eslint/no-explicit-any */
import snakeCaseKeys from 'snakecase-keys'
import camelCaseKeys from 'camelcase-keys'

export default class Fetch {
  private baseUrl: string
  private snakeCaseKeysData: FetchConfig['snakeCaseKeysData']
  private timeout: FetchConfig['timeout']
  private errorHandler: FetchConfig['errorHandler']

  constructor(baseUrl: string, config?: FetchConfig) {
    this.baseUrl = baseUrl

    const { snakeCaseKeysData = false, timeout = 60000, errorHandler = null } = config ?? {}
    this.snakeCaseKeysData = snakeCaseKeysData
    this.timeout = timeout
    this.errorHandler = errorHandler
  }

  private async fetch(input: RequestInfo, init?: RequestInit) {
    const timeout = this.timeout
    if (timeout === 0) {
      return fetch(input, init)
    }

    const controller = new AbortController()
    const timer = setTimeout(() => {
      return controller.abort()
    }, timeout)

    try {
      return await fetch(input, { ...init, signal: controller.signal })
    } finally {
      clearTimeout(timer)
    }
  }

  private paramKeys(params: Record<string, string>) {
    return this.snakeCaseKeysData ? snakeCaseKeys(params, { deep: true }) : params
  }

  private dataKeys(data: any) {
    return this.snakeCaseKeysData ? camelCaseKeys(data, { deep: true }) : data
  }

  private query(params: Record<string, string>) {
    if (!params) {
      return ''
    }
    return new URLSearchParams(this.paramKeys(params))
  }

  private async handleResponse(response: Promise<Response>) {
    try {
      const resp = (await response) as FetchResponse

      const contentType = resp.headers.get('Content-Type') ?? ''
      if (contentType.includes('application/json')) {
        resp.data = this.dataKeys(await resp.json())
      }
      if (contentType.includes('text/html')) {
        resp.data = await resp.text()
      }

      return resp
    } catch (error) {
      this.errorHandler?.(error?.name)
      return { ok: false } as FetchResponse
    }
  }

  async get(input: RequestInfo, { params, ...init }: FetchInit) {
    const response = this.fetch(`${this.baseUrl}${input}?${this.query(params)}`, {
      ...init,
    })
    return this.handleResponse(response)
  }

  async post(input: RequestInfo, { params, ...init }: FetchInit) {
    const response = this.fetch(`${this.baseUrl}${input}`, {
      ...init,
      method: 'POST',
      body: this.query(params),
    })
    return this.handleResponse(response)
  }

  async put(input: RequestInfo, { params, ...init }: FetchInit) {
    const response = this.fetch(`${this.baseUrl}${input}`, {
      ...init,
      method: 'PUT',
      body: this.query(params),
    })
    return this.handleResponse(response)
  }

  async putQuery(input: RequestInfo, { params, ...init }: FetchInit) {
    const response = this.fetch(`${this.baseUrl}${input}?${this.query(params)}`, {
      ...init,
      method: 'PUT',
    })
    return this.handleResponse(response)
  }

  async delete(input: RequestInfo, { params, ...init }: FetchInit) {
    const response = this.fetch(`${this.baseUrl}${input}?${this.query(params)}`, {
      ...init,
      method: 'DELETE',
    })
    return this.handleResponse(response)
  }
}

export type FetchConfig = { snakeCaseKeysData?: boolean; timeout?: number; errorHandler?: (name: string) => void }
export type FetchInit = RequestInit & { params?: Record<string, any> }
export type FetchResponse<T = any> = Response & { data: T }
