import React from 'react'
import { Chip } from '@mui/material'

export interface DiscountChipProps {
  discountLabel: string
}

const DiscountChip = ({ discountLabel }: DiscountChipProps) =>
  discountLabel && (
    <Chip
      sx={{
        bgcolor: '#f44336',
      }}
      color="primary"
      label={discountLabel}
      size="small"
    />
  )

export default DiscountChip
