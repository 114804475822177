import React from 'react'
import { IconButton, IconButtonProps, styled, Typography } from '@mui/material'

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  width: '5.6rem',
  height: '5.6rem',
  position: 'relative',
  '&.MuiIconButton-colorPrimary': {
    '& svg, & .MuiTouchRipple-root': {
      color: theme.palette.primary.main,
    },
  },
  '& > div:first-of-type, & > svg:first-of-type': {
    position: 'absolute',
    top: '1.2rem',
  },
  '& > .MuiTypography-caption': {
    position: 'absolute',
    top: '3.6rem',
    fontSize: '1rem',
    width: '6rem',
  },
}))

interface IconCaptionButton extends IconButtonProps {
  caption: string
}

const IconCaptionButton = ({ children, caption, ...rest }: IconCaptionButton) => {
  const textColor = rest.color === 'primary' ? 'primary' : 'textPrimary'
  return (
    <StyledIconButton {...rest}>
      {children}
      {caption && (
        <Typography variant="caption" color={textColor}>
          {caption}
        </Typography>
      )}
    </StyledIconButton>
  )
}

export default IconCaptionButton
