module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KZPR4L6","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#009fde"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ドワンゴジェイピー オーディオブック（旧：ListenGo）","short_name":"ドワンゴジェイピー オーディオブック","description":"ドワンゴが運営するオーディオブック配信サービス「ドワンゴジェイピー オーディオブック（旧ListenGo）」。本を耳で聴こう！","crossOrigin":"use-credentials","start_url":"/","background_color":"#e0e0e0","theme_color":"#009fde","display":"browser","icon":"static/logos/logo-1024.png","icons":[{"src":"/logos/logo-32.png","sizes":"32x32","type":"image/png"},{"src":"/logos/logo-48.png","sizes":"48x48","type":"image/png"},{"src":"/logos/logo-72.png","sizes":"72x72","type":"image/png"},{"src":"/logos/logo-96.png","sizes":"96x96","type":"image/png"},{"src":"/logos/logo-144.png","sizes":"144x144","type":"image/png"},{"src":"/logos/logo-192.png","sizes":"192x192","type":"image/png"},{"src":"/logos/logo-256.png","sizes":"256x256","type":"image/png"},{"src":"/logos/logo-384.png","sizes":"384x384","type":"image/png"},{"src":"/logos/logo-512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"c5ff4309674a39f08c2aac859f7f63dd"},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
