import React, { ChangeEvent } from 'react'

import { Slider as MuiSlider } from '@mui/material'
import { useAuditionPlayer } from '@/contexts/auditionPlayer'

interface SliderProps {
  audio: () => HTMLAudioElement
}

const Slider = ({ audio }: SliderProps) => {
  const { useSliderValue, useAuditionPlayerDispach } = useAuditionPlayer()

  const sliderValue = useSliderValue()
  const { setSliderValue, setSeeking } = useAuditionPlayerDispach()

  const pause = () => {
    if (audio()?.paused === false) {
      audio().pause()
    }
  }

  const handleSeek = (_: ChangeEvent, value: number) => {
    const currentTime = audio().duration * value
    audio().currentTime = currentTime
    if (audio().currentTime === audio().duration) {
      pause()
      audio().currentTime = 0
      setSliderValue(0)
      setSeeking(false)
    }
  }

  return (
    <MuiSlider
      size="small"
      step={0.001}
      max={1}
      value={sliderValue}
      onChange={(_, value: number) => setSliderValue(value)}
      onChangeCommitted={handleSeek}
      onMouseDown={() => setSeeking(true)}
      onTouchStart={() => setSeeking(true)}
      onMouseUp={() => setSeeking(false)}
      onTouchEnd={() => setSeeking(false)}
      sx={{
        p: '0.5rem 0',
        ['@media (pointer: coarse)']: {
          p: '0.5rem 0',
        },
      }}
    />
  )
}

export default Slider
