import React, { useEffect, useState } from 'react'
import AudiobookSlideList from '@/components/organisms/Audiobook/SlideList'
import { getHistories } from '@/libs/History'
import { Box, Typography } from '@mui/material'

interface SlideListProps {}

export const SlideList = (_: SlideListProps) => {
  const [collectionIds, setCollectionIds] = useState<string[]>([])

  useEffect(() => {
    setCollectionIds(
      getHistories()
        .reverse()
        .map((history) => history.pathname.match(/^\/audiobook\/(\d+)/)?.[1])
        .filter((val) => !!val),
    )
  }, [])

  return collectionIds?.length > 0 ? (
    <AudiobookSlideList collectionIds={collectionIds} />
  ) : (
    <Box textAlign="center" px={2} py={3}>
      <Typography>最近見た商品はありません。</Typography>
    </Box>
  )
}

export default SlideList
