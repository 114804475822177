import React from 'react'
import { styled, Typography, TypographyProps } from '@mui/material'
import theme from '@/theme'

interface HeadingH1Props extends TypographyProps {}

const StyledH1 = styled(Typography)({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: '2.4rem 1.6rem',
})

const HeadingH1 = ({ children }: HeadingH1Props) => <StyledH1 variant="h1">{children}</StyledH1>

export default HeadingH1
