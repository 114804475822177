import dayjs from 'dayjs'
export const available = ({ startDateTime, endDateTime }: { startDateTime?: string; endDateTime?: string }) => {
  const startDate = dayjs(startDateTime ?? '')
  const endDate = dayjs(endDateTime ?? '')
  const now = dayjs()

  return startDate.isValid() && endDate.isValid() && startDate.isBefore(now) && endDate.isAfter(now)
}

export const hasRewardChip = ({
  campaignrequirementpurchase,
}: {
  campaignrequirementpurchase: Queries.audiobookInfoQuery['contentfulAudiobook']['campaignrequirementpurchase']
}): boolean => {
  if (!campaignrequirementpurchase) {
    return false
  }

  const now = dayjs()
  return !!campaignrequirementpurchase.find(
    ({
      startDateTime,
      endDateTime,
    }: Queries.audiobookInfoQuery['contentfulAudiobook']['campaignrequirementpurchase'][0]) => {
      const startDate = dayjs(startDateTime ?? '')
      const endDate = dayjs(endDateTime ?? '')
      return startDate.isValid() && endDate.isValid() && startDate.isBefore(now) && endDate.isAfter(now)
    },
  )
}

export const hasNewChip = ({
  startDateTime,
  importedDateTime,
}: Pick<Queries.audiobookInfoQuery['contentfulAudiobook'], 'startDateTime' | 'importedDateTime'>): boolean =>
  (!!startDateTime && dayjs(startDateTime).isAfter(dayjs().subtract(1, 'month'))) ||
  (!!importedDateTime && dayjs(importedDateTime).isAfter(dayjs().subtract(1, 'month')))

export const hasChip = ({
  campaignrequirementpurchase,
  startDateTime,
  importedDateTime,
  discount,
}: Queries.audiobookInfoQuery['contentfulAudiobook']): boolean =>
  hasRewardChip({ campaignrequirementpurchase }) ||
  hasNewChip({ startDateTime, importedDateTime }) ||
  hasDiscountChip({ discount })

export const hasDiscountChip = ({
  discount,
}: {
  discount: Queries.audiobookInfoQuery['contentfulAudiobook']['discount']
}): boolean => {
  if (!discount) {
    return false
  }
  return !!findDiscount(discount)
}

export const findDiscount = (discount: Queries.audiobookInfoQuery['contentfulAudiobook']['discount']) => {
  const now = dayjs()
  return discount?.find(
    (discount) => now.isAfter(dayjs(discount.startDateTime)) && now.isBefore(dayjs(discount.endDateTime)),
  )
}

export const hasFreeMember = (discounts: Queries.audiobookInfoQuery['contentfulAudiobook']['discount']): boolean => {
  const discount = findDiscount(discounts)
  if (!discount) {
    return false
  }
  return !!discount.freeMember
}

export default { available, hasRewardChip, hasNewChip, hasChip, hasDiscountChip }
