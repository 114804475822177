import { get } from 'lodash'
export const env = get(
  {
    production: '',
    development: 'dev',
    test: 'test',
  },
  process.env.GATSBY_ACTIVE_ENV,
  'local',
) as string
