import React, { useMemo } from 'react'
import { ListItemButton, ListItemButtonProps } from '@mui/material'
import { useLocation } from '@reach/router'

interface SelectedListItemButtonProps extends ListItemButtonProps<'a'> {}

const SelectedListItemButton = ({ children, href, ...props }: SelectedListItemButtonProps) => {
  const location = useLocation()
  const selected = location.pathname.split('/').slice(0, 3).join('/') === href.split('/').slice(0, 3).join('/')
  return useMemo(
    () => (
      <ListItemButton selected={selected} href={href} {...props}>
        {children}
      </ListItemButton>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selected],
  )
}

export default SelectedListItemButton
