import React from 'react'
import { Box } from '@mui/material'
import theme from '@/theme'
import { HtmlAst } from '@/components/molecules'

interface PageHtmlContentsProps {
  description: Queries.findCampaignRequirementPurchaseQuery['contentfulCampaignRequirementPurchase']['description']
}
const PageHtmlContents = ({ description }: PageHtmlContentsProps) => {
  return (
    <Box mx={{ xs: 2 }} my={3} color={theme.palette.text.secondary}>
      {HtmlAst(description?.childMarkdownRemark?.htmlAst)}
    </Box>
  )
}

export default PageHtmlContents
