import React from 'react'
import { Box, CircularProgress, styled, Typography } from '@mui/material'

const Overlay = styled(Box)({
  width: '100%',
  height: '100%',
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 1500,
  backgroundColor: '#ffffff',
  opacity: 0.9,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  '& > .MuiCircularProgress-root': {
    marginBottom: '0.8rem',
  },
  '& > *': {
    textAlign: 'center',
  },
})

export interface LoadingOverlayProps {
  children?: React.ReactNode
}

const LoadingOverlay = ({ children }: LoadingOverlayProps) => (
  <Overlay>
    <CircularProgress size="4rem" />
    {children && (
      <Typography variant="caption" color="textPrimary">
        {children}
      </Typography>
    )}
  </Overlay>
)

export default LoadingOverlay
