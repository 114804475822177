import React from 'react'
import { Chip } from '@mui/material'

export interface NewChipProps {}

const NewChip = (_: NewChipProps) => (
  <Chip
    color="primary"
    sx={{
      bgcolor: '#FFCB3D',
    }}
    label="NEW"
    size="small"
  />
)

export default NewChip
