import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

interface AddPointProps extends SvgIconProps {}

const AddPoint = (props: AddPointProps) => (
  <SvgIcon {...props}>
    <path d="M9.7,6.5a1,1,0,0,0-1,1v9a1,1,0,0,0,2,0V13.1h2.042c2.248,0,3.758-1.327,3.758-3.3s-1.51-3.3-3.758-3.3Zm4.8,3.3c0,1.074-.956,1.3-1.758,1.3H10.7V8.5h2.042C13.544,8.5,14.5,8.726,14.5,9.8ZM13.232,20.963a1,1,0,0,1-.975,1.025C12.133,22,12.067,22,12,22A10,10,0,1,1,22,12c0,.067,0,.133-.009.2a1.035,1.035,0,0,1-1.026,1,.977.977,0,0,1-.977-.995L20,12.059A8,8,0,1,0,12,20l.207-.012A1.011,1.011,0,0,1,13.232,20.963ZM22,18a1,1,0,0,1-1,1H19v2a1,1,0,0,1-2,0V19H15a1,1,0,0,1,0-2h2V15a1,1,0,0,1,2,0v2h2A1,1,0,0,1,22,18Z" />
  </SvgIcon>
)

export default AddPoint
