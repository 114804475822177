import React, { useEffect, useState, useImperativeHandle, ForwardRefRenderFunction, useRef, forwardRef } from 'react'
import { useConnect } from '@/contexts/connect'
import { setMediaMetadata } from '@/contexts/audio'
import AuditionErrorSnackbar from '@/components/molecules/AuditionErrorSnackbar'
import { useAudition } from '@/contexts/audition'
import { useAuditionPlayer } from '@/contexts/auditionPlayer'

import { Box, IconButton, Slide, Paper, styled, useMediaQuery, Theme } from '@mui/material'
import { PauseRounded, PlayArrowRounded, CloseRounded } from '@mui/icons-material'
import { GoogleTagManagerEvent, SpringText } from '@/components/atoms'
import TimeBox from './TimeBox'
import Slider from './Slider'

const StyleIconButton = styled(IconButton)({
  width: '4.8rem',
  height: '4.8rem',
})

const StylePaper = styled(Paper)(({ theme }) => ({
  display: 'inline-flex',
  width: 'calc(100% - 4rem)',
  alignItems: 'center',
  background: theme.palette.background.default,
}))

const SeekBar = styled(Box)({
  alignItems: 'center',
  width: '100%',
})

export interface AuditionPlayerHandles {
  play?: () => void
  pause?: () => void
}

interface AuditionPlayerProps {
  audiobook: Pick<
    Queries.audiobookInfoQuery['contentfulAudiobook'],
    'collectionId' | 'title' | 'coverArt' | 'authors' | 'sampleFile'
  >
}

const AuditionPlayer: ForwardRefRenderFunction<AuditionPlayerHandles, AuditionPlayerProps> = (
  { audiobook: { collectionId, title, coverArt, authors, sampleFile } }: AuditionPlayerProps,
  ref,
) => {
  const { content } = useConnect()
  const { useCollectionId, useAuditionDispach } = useAudition()
  const { useSeeking, useAuditionPlayerDispach } = useAuditionPlayer()

  const seeking = useSeeking()
  const currentCollectionId = useCollectionId()
  const { setCollectionId } = useAuditionDispach()
  const { setSliderValue, setPaused, setLoading } = useAuditionPlayerDispach()

  const [hasError, setHasError] = useState(false)
  const [errorSnackbar, setErrorSnackbar] = useState(false)
  const [seekBarClose, setSeekBarClose] = useState(true)
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  useImperativeHandle(ref, () => ({
    play() {
      handlePlay()
    },
    pause() {
      pause()
    },
  }))

  const pause = () => {
    if (audio()?.paused === false) {
      audio().pause()
    }
  }

  const handleError = () => {
    pause()
    setSeekBarClose(true)
    setPaused(true)
    setHasError(true)
    setErrorSnackbar(true)
  }

  const handlePlay = async () => {
    if (!audio().src) {
      setLoading(true)
      try {
        audio().src = await content(sampleFile?.resource_id, collectionId, false)
      } catch (e) {
        setHasError(true)
        setErrorSnackbar(true)
        setLoading(false)
        return
      }
    }
    if (hasError && audio()?.paused) {
      setHasError(false)
      setErrorSnackbar(false)
    }

    setCollectionId(collectionId)
    if (audio()?.paused) {
      await audio().play()
      setPaused(false)
      setLoading(false)
      setMediaMetadata({
        title: `[試聴中] ${title}`,
        album: title,
        authors: authors,
        url: coverArt?.file.url,
        contentType: coverArt?.file.contentType,
      })
    } else {
      pause()
    }
    setSeekBarClose(false)
  }

  const handleTimeUpdate = () => {
    if (!seeking) {
      const { currentTime, duration } = audio()
      setSliderValue(currentTime / duration)
      if (hasError && !audio().paused) {
        setHasError(false)
        setErrorSnackbar(false)
      }
    }
    if (audio().currentTime === audio().duration) {
      pause()
    }
  }

  const audioRef = useRef({
    paused: true,
    currentTime: 0,
    duration: 0,
    playbackRate: 1,
    volume: 1,
  } as HTMLAudioElement)

  const audio = () => audioRef.current

  useEffect(() => {
    if (currentCollectionId !== collectionId) {
      if (audio()?.src && !hasError) {
        audio().currentTime = 0
        pause()
        setSliderValue(0)
      }
      setSeekBarClose(true)
      setHasError(false)
      setErrorSnackbar(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCollectionId])

  useEffect(() => {
    return () => {
      pause()
      setCollectionId('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Box
        sx={{
          bottom: '2rem',
          right: 0,
          textAlign: 'center',
          position: 'fixed',
          zIndex: 10,
          width: { md: 'calc(100% - 25.2rem)', xs: '100%' },
          height: { md: 0 },
          top: { md: '11.6rem' },
        }}
      >
        <Slide direction={isMobile ? 'up' : 'down'} in={!seekBarClose} mountOnEnter unmountOnExit>
          <StylePaper sx={{ elevation: 3 }}>
            <SeekBar sx={{ pt: 1, pl: 0.5, pr: 1, pb: 1, display: 'flex' }}>
              <Box>
                <IconButton
                  onClick={() => {
                    pause()
                    setSeekBarClose(true)
                    setCollectionId('')
                  }}
                  sx={{
                    width: '3.2rem',
                    height: '3.2rem',
                  }}
                >
                  <CloseRounded
                    sx={{
                      fontSize: '2rem',
                    }}
                  />
                </IconButton>
              </Box>
              <Box sx={{ pl: 0.5, mr: 2, textAlign: 'left', width: 'calc(100% - 8.8rem)' }}>
                <SpringText sx={{ fontSize: '1.2rem', variant: 'caption', display: 'block' }}>{title}</SpringText>
                <Box sx={{ display: 'flex', mt: '0.6rem', alignItems: 'center' }}>
                  <TimeBox audio={audio} />
                  <Box sx={{ width: '0.4rem' }} />
                  <Slider audio={audio} />
                </Box>
              </Box>
              <Box>
                <GoogleTagManagerEvent action="trial_listening" category={title}>
                  {!audio()?.paused ? (
                    <StyleIconButton onClick={() => pause()}>
                      <PauseRounded sx={{ fontSize: 'large' }} />
                    </StyleIconButton>
                  ) : (
                    <StyleIconButton onClick={() => handlePlay()}>
                      <PlayArrowRounded sx={{ fontSize: 'large' }} />
                    </StyleIconButton>
                  )}
                </GoogleTagManagerEvent>
              </Box>
            </SeekBar>
          </StylePaper>
        </Slide>
      </Box>
      <AuditionErrorSnackbar open={errorSnackbar} onClose={() => setErrorSnackbar(false)} />
      {!hasError && (
        <audio
          ref={audioRef}
          title={`試聴中 - ${title}`}
          preload="metadata"
          onTimeUpdate={handleTimeUpdate}
          onEnded={() => {
            pause()
            setSeekBarClose(true)
            setCollectionId('')
          }}
          onError={handleError}
          onPlay={() => setPaused(false)}
          onPause={() => setPaused(true)}
        />
      )}
    </>
  )
}

export default forwardRef(AuditionPlayer)
