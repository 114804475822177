import React from 'react'
import { Link, LinkProps } from '@mui/material'
import { HitAudioBook } from '@/libs/Algolia'
import { SearchFormHitText } from '@/components/atoms'
import { searchInsightClient, INSIGHT_METHOD_NAME } from '@/libs/Algolia'
import { useConnectStates } from '@/contexts/connect'

export interface HitProps extends LinkProps {
  hit: HitAudioBook
  queryID: string
  position: number
}

const Hit = ({ hit, queryID, position }: HitProps) => {
  const { userinfo } = useConnectStates(['userinfo'])

  return (
    <Link
      href={`/audiobook/${hit.objectID}?queryId=${queryID}`}
      onClick={() =>
        searchInsightClient(INSIGHT_METHOD_NAME.clickedObjectIDs, {
          queryID: queryID,
          objectIDs: [hit.objectID],
          positions: [position],
          index: process.env.ALGOLIA_INDEX_NAME,
          eventName: 'click header form',
          userToken: userinfo?.userId,
        })
      }
    >
      <SearchFormHitText variant="body2" color="inherit">
        {hit.title}
      </SearchFormHitText>
      {hit.authors?.[0]?.name && (
        <SearchFormHitText className="author" variant="body2" color="inherit">
          {hit.authors[0].name}
        </SearchFormHitText>
      )}
    </Link>
  )
}
export default Hit
