import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'
import theme from '@/theme'

interface TwitterProps extends SvgIconProps {}

const Twitter = (props: TwitterProps) => (
  <SvgIcon
    sx={{
      fill: theme.palette.text.primary,
      width: '2.4rem',
      height: '2.4rem',
      '&.MuiSvgIcon-fontSizeLarge': {
        fill: theme.palette.text.secondary,
        width: '3.2rem',
        height: '3.2rem',
      },
    }}
    {...props}
  >
    <svg viewBox="0 0 24 24">
      <g transform="translate(-577 -598)">
        <path
          d="M9.317,6.775,15.146,0H13.765L8.7,5.883,4.662,0H0L6.112,8.9,0,16H1.381L6.725,9.788,10.994,16h4.662L9.317,6.775Zm-1.892,2.2-.619-.886L1.879,1.04H4L7.977,6.728l.619.886,5.169,7.394H11.644L7.426,8.974Z"
          transform="translate(581.172 602)"
        />
      </g>
    </svg>
  </SvgIcon>
)

export default Twitter
