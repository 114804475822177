import React from 'react'
import { Box, ListItem as MuiListItem, Typography } from '@mui/material'
import { Variant } from '@mui/material/styles/createTypography'

interface ListItemProps {
  primary: string
  secondary: string | React.ReactNode
  valiant?: Variant
}

const ListItemText = ({ primary, secondary: secondary, valiant }: ListItemProps) => (
  <MuiListItem>
    <Box display="flex" mb={1}>
      <Box minWidth="1.6rem">
        <Typography variant={valiant} color="textSecondary">
          {primary}
        </Typography>
      </Box>
      <Typography component="span" variant={valiant} color="textSecondary">
        {secondary}
      </Typography>
    </Box>
  </MuiListItem>
)

export default ListItemText
