import React, { useEffect, useState, useMemo } from 'react'
import {
  Typography,
  styled,
  Box,
  Drawer,
  Button,
  Divider,
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  ListItemButton,
} from '@mui/material'
import {
  HomeRounded,
  AddCircleRounded,
  MenuBookRounded,
  PersonRounded,
  HistoryRounded,
  MusicNote,
  FavoriteRounded,
} from '@mui/icons-material'

import { CloseButton, SelectedListItemButton } from '@/components/atoms'
import { CategoryMenu } from '@/components/molecules'
import { Beginner, SignIn, SignOut } from '@/components/SVG/Icons'
import CountUp from 'react-countup'
import { useConnect, useConnectStates } from '@/contexts/connect'
import { drawerWidth, headerHeight } from '@/theme'
import { dwjpUrl } from '@/libs/Dwango.jp'
import { useApp } from '@/contexts/app'

const ResponsiveDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    [theme.breakpoints.up('md')]: {
      zIndex: 1000,
      top: headerHeight.md,
      height: `calc(100% - ${headerHeight.md})`,
      '&:hover': {
        overflowY: 'scroll',
        scrollbarWidth: 'thin',
      },
      ['@media (pointer: fine)']: {
        overflowY: 'hidden',
        '&:hover': { overflowY: 'overlay' },
      },
    },
  },
}))

const SmallText = styled(Typography)(({ theme }) => ({
  fontSize: '1.2rem',
  [theme.breakpoints.up('md')]: {
    width: '13.2rem',
  },
}))

export interface DrawerMenuProps {}

const DrawerMenu = (_: DrawerMenuProps) => {
  const { rerender, hasCookie, logout } = useConnect()
  const { userinfo } = useConnectStates(['userinfo'])
  const { useDrawerOpen, useAppDispach } = useApp()
  const drawerOpen = useDrawerOpen()
  const { setDrawerOpen } = useAppDispach()
  const { siteAvailable, hasPoint } = userinfo ?? { hasPoint: 0 }
  const [startPoint, setStartPoint] = useState(0)
  const hasCookieLocal = hasCookie()

  const handleClose = () => {
    setDrawerOpen(false)
  }

  useEffect(() => {
    setTimeout(() => setStartPoint(hasPoint), 750)
  }, [hasPoint])

  const drawer = (
    <Box component="nav" width={drawerWidth}>
      <CloseButton onClick={handleClose} sx={{ display: { md: 'none', xs: 'flex' } }}>
        メニューを閉じる
      </CloseButton>

      <Divider />

      <Box px={2}>
        <Box py={2}>
          {!hasCookieLocal && (
            <Button
              variant="contained"
              color="primary"
              size="small"
              href="/information/create_account"
              onClick={handleClose}
            >
              まずは無料でアカウント作成♪
            </Button>
          )}

          {hasCookieLocal &&
            (siteAvailable ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography variant="body2" sx={{ mr: 'auto' }}>
                  所持ポイント
                </Typography>
                <Typography variant="body1">
                  <CountUp
                    start={startPoint}
                    end={hasPoint}
                    duration={0.75}
                    formattingFn={(num) => num.toLocaleString()}
                  />
                </Typography>
                <Typography variant="body1">pt</Typography>
                <IconButton
                  color="primary"
                  sx={{
                    ml: 1,
                    p: 0,
                  }}
                  href="/information/purchase_points"
                  onClick={handleClose}
                >
                  <AddCircleRounded color="primary" fontSize="large" />
                </IconButton>
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <SmallText variant="body2" sx={{ mr: 'auto' }}>
                  月額コースへ入会して
                  <br />
                  ポイントを獲得しましょう♪
                </SmallText>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  href="/information/purchase_points"
                  onClick={handleClose}
                >
                  入会する
                </Button>
              </Box>
            ))}
        </Box>
        <Divider />
      </Box>

      {hasCookieLocal ? (
        <List component="ul">
          <SelectedListItemButton href="/mypage/" onClick={handleClose}>
            <ListItemIcon>
              <PersonRounded fontSize="large" color="secondary" />
            </ListItemIcon>
            <ListItemText primary="マイページ" />
          </SelectedListItemButton>
          <SelectedListItemButton href="/purchase/history/" onClick={handleClose}>
            <ListItemIcon>
              <MenuBookRounded fontSize="large" color="secondary" />
            </ListItemIcon>
            <ListItemText primary="購入履歴" />
          </SelectedListItemButton>
          <SelectedListItemButton href="/favorite/" onClick={handleClose}>
            <ListItemIcon>
              <FavoriteRounded fontSize="large" color="secondary" />
            </ListItemIcon>
            <ListItemText primary="お気に入り" />
          </SelectedListItemButton>
          <ListItemButton
            href="/"
            onClick={async () => {
              await logout()
              handleClose()
              rerender()
            }}
          >
            <ListItemIcon>
              <SignOut fontSize="large" color="secondary" />
            </ListItemIcon>
            <ListItemText primary="ログアウト" />
          </ListItemButton>
        </List>
      ) : (
        <List component="ul">
          <SelectedListItemButton href="/signin/" onClick={handleClose}>
            <ListItemIcon>
              <SignIn fontSize="large" color="secondary" />
            </ListItemIcon>
            <ListItemText primary="ログイン" />
          </SelectedListItemButton>
        </List>
      )}

      <Divider />

      <List sx={{ pb: 0 }}>
        <SelectedListItemButton href="/" onClick={handleClose}>
          <ListItemIcon>
            <HomeRounded fontSize="large" color="secondary" />
          </ListItemIcon>
          <ListItemText primary="ホーム" />
        </SelectedListItemButton>
        <SelectedListItemButton href="/welcome/" onClick={handleClose}>
          <ListItemIcon>
            <Beginner fontSize="large" color="secondary" />
          </ListItemIcon>
          <ListItemText primary="オーディオブックの楽しみ方" />
        </SelectedListItemButton>
        <SelectedListItemButton href={dwjpUrl()} onClick={handleClose}>
          <ListItemIcon>
            <MusicNote fontSize="large" color="secondary" />
          </ListItemIcon>
          <ListItemText primary="音楽配信サービスへ" />
        </SelectedListItemButton>
        <SelectedListItemButton href="/history/" onClick={handleClose}>
          <ListItemIcon>
            <HistoryRounded fontSize="large" color="secondary" />
          </ListItemIcon>
          <ListItemText primary="最近見た商品" />
        </SelectedListItemButton>
      </List>

      <ListSubheader component="div" disableSticky={true} sx={{ fontWeight: 'bold' }}>
        カテゴリで探す
      </ListSubheader>
      <CategoryMenu onClick={handleClose} />

      <Divider />

      <List>
        <ListItemButton href="https://dwjplistengo.zendesk.com/hc/ja/articles/900004608123">
          <ListItemText primary="機種変更の方" />
        </ListItemButton>
        <ListItemButton href="https://dwjplistengo.zendesk.com/hc/ja/articles/900003153143">
          <ListItemText primary="動作確認環境" />
        </ListItemButton>
        <ListItemButton href="https://dwjplistengo.zendesk.com/hc/ja">
          <ListItemText primary="ヘルプ・お問い合わせ" />
        </ListItemButton>
        <ListItemButton href={process.env.ACCOUNT_CENTER_URL}>
          <ListItemText primary="コース退会 / アカウント削除" />
        </ListItemButton>
      </List>
    </Box>
  )
  return useMemo(() => {
    return (
      <>
        <ResponsiveDrawer
          open={drawerOpen}
          onClose={handleClose}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{ display: { md: 'none', xs: 'block' } }}
        >
          {drawer}
        </ResponsiveDrawer>
        <ResponsiveDrawer
          open
          variant="permanent"
          PaperProps={{ elevation: 3 }}
          sx={{ display: { xs: 'none', md: 'block' } }}
        >
          {drawer}
        </ResponsiveDrawer>
      </>
    )
    // eslint-disable-next-line
  }, [hasCookieLocal, siteAvailable, hasPoint, drawerOpen])
}

export default DrawerMenu
