import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { SigninOverlay } from '@/components/organisms'
import { Typography } from '@mui/material'
import { Favorite, useConnect } from './connect'
import { has, map, omit } from 'lodash'
import { sendEvent } from '@/libs/GoogleTagManager'

interface FavoriteContextProps {
  itemPerPage: number
  favoriteProps: (favorite: Favorite) => {
    list: Favorite['list']
    count: number
    isDeleted: (collectionId: string) => boolean
    itemFavorite: (collectionId: string) => {
      isFavorite: boolean
      onClick: (collectionId: string, title: string) => Promise<void>
    }
  }
}
const FavoriteContext = createContext<FavoriteContextProps>(undefined)
export const useFavorite = () => useContext(FavoriteContext)

type DummyFavorite = {
  [key: string]: boolean
}

interface FavoriteProviderProps {
  path: string
  children: ReactNode
}

export interface ItemFavoriteProps {
  isFavorite: boolean
  onClick: (collectionId: string, title: string) => Promise<void>
}

export const FavoriteProvider = ({ path, children }: FavoriteProviderProps) => {
  const itemPerPage = 24
  const [showSigninOverlay, setShowSigninOverlay] = useState(false)
  const { hasCookie, favorite: favFetch, mutateAll } = useConnect()
  const [apiTimeout, setApiTimeout] = useState<NodeJS.Timeout>(undefined)
  const [dummyFavorite, setDummyFavorite] = useState<DummyFavorite>({} as DummyFavorite)

  useEffect(() => {
    apiTimeout && clearTimeout(apiTimeout)
    if (!hasCookie() || Object.keys(dummyFavorite).length === 0) {
      return
    }
    setApiTimeout(
      setTimeout(async () => {
        await Promise.all(
          map(dummyFavorite, async (isFavorite, collectionId) => {
            if (isFavorite) {
              return favFetch.post(collectionId)
            } else {
              return favFetch.delete(collectionId)
            }
          }),
        )
        mutateAll()
      }, 0.5 * 1000),
    )
    return () => apiTimeout && clearTimeout(apiTimeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dummyFavorite])

  // 再レンダリング防止の為dummyFavoriteが同じ画面にいる限り増え続けるため、pathが変わるタイミングで初期化している
  useEffect(() => {
    setDummyFavorite({})
  }, [path])

  const favoriteProps = (favorite: Favorite) => {
    const itemFavorite = (collectionId: string) => {
      const isFavorite = has(dummyFavorite, collectionId)
        ? dummyFavorite[collectionId]
        : favorite?.list?.some((item) => item.collectionId === collectionId)
      return {
        isFavorite,
        onClick: async (collectionId: string, title: string) => {
          if (!hasCookie()) {
            setShowSigninOverlay(true)
            return
          }
          const nowFavorite = favorite?.list?.some((item) => item.collectionId === collectionId)
          if (nowFavorite === !isFavorite) {
            setDummyFavorite((dummyFavorite) => ({
              ...omit(dummyFavorite, collectionId),
            }))
          } else {
            setDummyFavorite((dummyFavorite) => ({
              ...dummyFavorite,
              [collectionId]: !isFavorite,
            }))
          }
          sendEvent('favorite', collectionId, title, !isFavorite ? 1 : 0)
        },
      }
    }

    return {
      itemPerPage,
      list: favorite?.list,
      isDeleted: (collectionId: string) => {
        return has(dummyFavorite, collectionId) && !dummyFavorite[collectionId]
      },
      count: favorite?.totalResults ? Math.ceil(favorite?.totalResults / itemPerPage) : 0,
      itemFavorite,
    }
  }

  return (
    <FavoriteContext.Provider
      value={{
        itemPerPage,
        favoriteProps,
      }}
    >
      <SigninOverlay
        overrideMessage={
          <Typography variant="body1">
            無料アカウント作成をして
            <br />
            お気に入り機能を使ってみましょう♪
          </Typography>
        }
        open={showSigninOverlay}
        onClose={() => setShowSigninOverlay(false)}
      />
      {children}
    </FavoriteContext.Provider>
  )
}
