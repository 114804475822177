import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

interface VolumeMuteProps extends SvgIconProps {}

const VolumeMute = (props: VolumeMuteProps) => (
  <SvgIcon {...props}>
    <path d="M11.607,6.354v11.17a1,1,0,0,1-1.71.71l-3.29-3.29h-3a1,1,0,0,1-1-1v-4a1,1,0,0,1,1-1h3l3.29-3.3a1,1,0,0,1,1.71.71ZM18.414,12,20,10.414A1,1,0,1,0,18.586,9L17,10.586,15.414,9A1,1,0,0,0,14,10.414L15.586,12,14,13.586A1,1,0,0,0,15.414,15L17,13.414,18.586,15A1,1,0,1,0,20,13.586Z" />
  </SvgIcon>
)

export default VolumeMute
