import React, { ForwardRefRenderFunction, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import Cookie from 'js-cookie'
import { REPEAT_STATE } from '@/components/organisms/Audiobook/Player/Repeat'
import { sendEvent } from '@/libs/GoogleTagManager'
import { useConnect } from '@/contexts/connect'
import { Chapter } from '@/components/organisms/Audiobook/Player/Drawer/Chapters'
import { isSafari } from 'react-device-detect'
import { isNaN } from 'lodash'
import { useLocation } from '@reach/router'
import { useAudio } from '@/contexts/audio'

export interface AudioHandles {
  audio: (newAudiobook?: Audiobook) => HTMLAudioElement
  play: () => Promise<void>
  pause: () => void
  stop: () => void
  replay: () => void
  forword: () => void
  prev: () => void
  next: () => void
  selectChapter: (chapter: Chapter, play?: boolean) => Promise<void>
  setVolume: (volume: number) => void
  setPlaybackRate: (playbackRate: number) => void
}

interface AudioProps {}

export const Audio: ForwardRefRenderFunction<AudioHandles, AudioProps> = (_, ref) => {
  const { content } = useConnect()
  const { useAudiobook, useLoading, useSeeking, useChapter, useChapterIndex, useRepeat, useHasError, useAudioDispach } =
    useAudio()

  const audiobook = useAudiobook()
  const {
    setSliderValue,
    setLoading,
    setAudiobook,
    setSeeking,
    setChapter,
    setChapterIndex,
    setHasError,
    setVolume: dispatchVolume,
    setPlaybackRate: dispatchPlaybackRate,
  } = useAudioDispach()

  const { title, collectionId, authors, coverArt, chapterArray } = audiobook ?? ({} as Audiobook)

  const loading = useLoading()
  const seeking = useSeeking()
  const chapter = useChapter()
  const chapterIndex = useChapterIndex()
  const repeat = useRepeat()
  const hasError = useHasError()
  const [onLoad, setOnLoad] = useState(false)

  const audioRef = useRef({
    paused: true,
    currentTime: 0,
    duration: 0,
    playbackRate: 1,
    volume: 1,
  } as HTMLAudioElement)

  useImperativeHandle(ref, () => ({
    play,
    audio,
    pause() {
      audio()?.pause()
    },
    stop,
    replay,
    forword,
    prev,
    next,
    selectChapter,
    setVolume,
    setPlaybackRate,
  }))

  const audio = (newAudiobook?: Audiobook) => {
    if (newAudiobook) {
      setAudiobook(newAudiobook)
      setHasError(false)
      setSeeking(false)
      setSliderValue(0)
    }
    return audioRef.current
  }

  const play = async () => {
    if (redumeError()) {
      return
    }
    if (audio()?.paused) {
      await audio().play()
      setMediaMetadata({
        title: audiobook.title,
        album: title,
        authors: authors,
        url: coverArt?.file?.url,
        contentType: coverArt?.file?.contentType,
      })
    } else {
      audio()?.pause()
    }
  }

  const stop = () => {
    setAudiobook(undefined)
    setSrc()
    setHasError(false)
    setSeeking(false)
    setSliderValue(0)
  }

  const replay = () => {
    audio().currentTime -= 30
  }

  const forword = () => {
    audio().currentTime += 30
  }

  const prev = async () => {
    //currentTimeが3秒を超えている場合0に戻す
    if (audio().currentTime > 3) {
      audio().currentTime = 0
      return
    }

    if (chapterIndex === 0) {
      if (repeat === REPEAT_STATE.ON) {
        const newChapter = chapterArray.slice(-1).pop()
        await selectChapter(newChapter)
      } else {
        audio().currentTime = 0
      }
      return
    }

    const newChapter = chapterArray[chapterIndex - 1]
    if (newChapter) {
      await selectChapter(newChapter)
    }
  }

  const next = async (play?: boolean) => {
    const newChapter = chapterArray[chapterIndex + 1]
    Cookie.remove('chapter_index')
    Cookie.remove('current_time')
    if (newChapter) {
      await selectChapter(newChapter, play)
      return
    }
    if (repeat === REPEAT_STATE.ON) {
      await selectChapter(chapterArray[0], play)
      return
    }
  }

  const setSrc = async (chapter?: Chapter, collectionId?: Audiobook['collectionId']) => {
    if (!audio()) {
      return
    }
    if (!chapter || !collectionId) {
      if (audio().src) {
        audio().src = ''
      }
      return
    }
    audio().src = await content(chapter.resourceId, collectionId)
    // safari は onloadedmetadata を待つことでその後のAPI操作が可能になるため待つ
    if (isSafari) {
      await new Promise((resolve) => {
        audio().onloadedmetadata = () => {
          resolve(undefined)
        }
      })
    }
  }

  const selectChapter = async (chapter: Chapter, play?: boolean) => {
    setHasError(false)
    play = play ?? !audio().paused
    audio().pause()
    setLoading(true)
    await setSrc(chapter, collectionId)
    play && audio().play()
    setChapter(chapter)

    const chapterIndex = chapterArray.findIndex(({ sequence }) => sequence === chapter.sequence)
    setChapterIndex(chapterIndex)
    Cookie.set('chapter_index', chapterIndex.toString())

    sendEvent('select_chapter', title, chapter.sequence)
  }

  const setVolume = (volume: number) => {
    audio().volume = volume
    dispatchVolume(volume)
    Cookie.set('volume', volume.toString())
  }

  const setPlaybackRate = (playbackRate: number) => {
    audio().playbackRate = playbackRate
    Cookie.set('playback_rate', playbackRate.toString())
    dispatchPlaybackRate(playbackRate)
    sendEvent('rate_change', title, chapter.sequence, playbackRate)
  }

  const handleLoadStart = () => {
    setLoading(true)
  }

  const handleLoadedMetadata = () => {
    const { lastCollectionId, chapterIndex, currentTime, playbackRate } = getResumeCookie()
    // 初回ロード時のresume処理
    if (!onLoad && collectionId === lastCollectionId && !isNaN(chapterIndex) && !isNaN(currentTime)) {
      audio().currentTime = Number(currentTime)
    }
    setOnLoad(true)
    setPlaybackRate(playbackRate)
    handleTimeUpdate()
    setLoading(false)
  }

  const { pathname } = useLocation()

  const handleTimeUpdate = () => {
    if (!seeking && pathname.includes('player')) {
      const { currentTime, duration } = audio()
      setSliderValue(isNaN(currentTime / duration) ? 0 : currentTime / duration)
      if (hasError && !audio().paused) {
        setHasError(false)
      }
      if (!loading) {
        Cookie.set('current_time', currentTime.toString())
      }
    }
  }

  const handleEnded = async () => {
    setSeeking(false)
    const chapterIndex = chapterArray.findIndex(({ sequence }) => sequence === chapter.sequence)
    const lastChapterIndex = chapterArray.length - 1

    switch (repeat) {
      case REPEAT_STATE.OFF:
        {
          next(true)
        }
        break
      case REPEAT_STATE.ON:
        {
          if (chapterIndex === lastChapterIndex) {
            selectChapter(chapterArray[0], true)
            return
          }
          next(true)
        }
        break
      case REPEAT_STATE.ONE:
        {
          audio().currentTime = 0
          audio().play()
        }
        break
    }
  }

  const handleError = () => {
    audio()?.pause()
    audiobook && pathname.includes('/player') && setHasError(true)
  }

  const redumeError = () => {
    if (!hasError) {
      return false
    }
    // audioタグはonErrorの再開にsrcの再設定が必要、selectChapter()から必要箇所のみ抜粋して再開させる
    const resume = async () => {
      setHasError(false)
      await setSrc(chapter, collectionId)
      audio().play()
    }
    resume()
    return true
  }

  useEffect(() => {
    // Chrome がアンマウント時にhandleTimeUpdate()をcurrentTime=0で呼び出しcookieを上書きする対策
    const unmounting = () => {
      setOnLoad(false)
      setLoading(true)
    }
    if (!audiobook) {
      return unmounting
    }
    const { lastCollectionId, chapterIndex, playbackRate } = getResumeCookie()

    if (collectionId === lastCollectionId) {
      if (!isNaN(chapterIndex)) {
        selectChapter(chapterArray[chapterIndex])
      } else {
        selectChapter(chapterArray[0])
        Cookie.remove('current_time')
      }
    } else {
      selectChapter(chapterArray[0])
      Cookie.remove('current_time')
    }
    Cookie.set('last_collection_id', collectionId)

    if (playbackRate) {
      Cookie.set('playback_rate', playbackRate.toString())
    } else {
      Cookie.set('playback_rate', '1')
    }

    const volume = Cookie.get('volume')
    if (volume) {
      setVolume(Number(volume))
    }
    return unmounting
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audiobook])

  return (
    <audio
      ref={audioRef}
      title={`${chapter.title} - ${title}`}
      preload="metadata"
      onLoadStart={handleLoadStart}
      onLoadedMetadata={handleLoadedMetadata}
      onTimeUpdate={handleTimeUpdate}
      onEnded={handleEnded}
      onError={handleError}
    />
  )
}

const getResumeCookie = () => {
  const lastCollectionId = Cookie.get('last_collection_id')
  const chapterIndex = Number(Cookie.get('chapter_index'))
  const currentTime = Number(Cookie.get('current_time'))
  const playbackRate = Number(Cookie.get('playback_rate'))

  return { lastCollectionId, chapterIndex, currentTime, playbackRate }
}

const setMediaMetadata = ({
  title,
  authors,
  album,
  url,
  contentType,
}: {
  title: string
  authors: Queries.audiobookPlayerQuery['contentfulAudiobook']['authors']
  album: string
  url: string
  contentType: string
}) => {
  if (!('mediaSession' in navigator)) {
    return
  }

  navigator.mediaSession.metadata = new MediaMetadata({
    title,
    artist: authors
      ?.map((author) => author.names.find((name) => name.languageAndScriptCode === 'ja-Hani').text)
      .join(' '),
    album,
    artwork:
      url &&
      [1024, 96, 128, 192, 256, 384, 512].map((size) => ({
        src: `${url}?w=${size}&h=${size}&q=50`,
        sizes: `${size}x${size}`,
        type: contentType,
      })),
  })
}

export type Audiobook = Pick<
  Queries.audiobookPlayerQuery['contentfulAudiobook'],
  'title' | 'collectionId' | 'authors' | 'coverArt'
> & {
  chapterArray: Chapter[]
}

export default forwardRef(Audio)
