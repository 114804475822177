import React from 'react'
import { styled, List as MuiList, ListProps as MuiListProps } from '@mui/material'

const StyledList = styled(MuiList)({
  '& .MuiListItem-gutters': {
    padding: 0,
  },
})

interface ListProps extends MuiListProps {}

const List = ({ children, ...rest }: ListProps) => <StyledList {...rest}>{children} </StyledList>

export default List
