import React from 'react'
import Audiobook from '@/libs/Audiobook'
import { AudiobookItem } from '@/components/organisms'
import { List } from '@/components/atoms/Audiobook'
import { useConnectStates } from '@/contexts/connect'
import { useFavorite } from '@/contexts/favorite'

interface PageTargetContentsProps {
  audiobookList?:
    | Queries.findPageQuery['contentfulPage']['audiobookList'][0]['audiobooks']
    | Queries.findCampaignRequirementPurchaseQuery['contentfulCampaignRequirementPurchase']['purchaseAudiobookContent']
}
type Audiobook = PageTargetContentsProps['audiobookList'][0]

const PageTargetContents = ({ audiobookList }: PageTargetContentsProps) => {
  const collectionIds = audiobookList?.map((audiobook: Audiobook) => audiobook.collectionId)
  const { favorite } = useConnectStates([['favorite', { collectionIds }]])
  const { favoriteProps } = useFavorite()
  const { itemFavorite } = favoriteProps(favorite)

  return (
    <>
      {audiobookList?.length > 0 && (
        <List>
          {audiobookList.filter(Audiobook.available).map((audiobook: Audiobook, i: number) => (
            <AudiobookItem key={i} {...audiobook} favorite={itemFavorite(audiobook.collectionId)} />
          ))}
        </List>
      )}
    </>
  )
}

export default PageTargetContents
