import React from 'react'
import { List as MuiList, ListProps as MuiListProps } from '@mui/material'
import theme from '@/theme'

export interface ListProps extends MuiListProps {}

const List = (props: ListProps) => (
  <MuiList
    sx={{
      display: 'flex',
      flexDirection: 'column',
      p: 0,
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        pt: 3,
      },
    }}
    {...props}
  >
    {props.children}
  </MuiList>
)

export default List
