import React from 'react'
import { Button, ButtonProps, styled } from '@mui/material'
import { CloseRounded } from '@mui/icons-material'

const StyledButton = styled(Button)({
  width: '100%',
  height: '6.4rem',
  padding: '2.4rem 1.6rem',
  fontWeight: 'bold',
  justifyContent: 'left',
})

export interface CloseButtonProps extends ButtonProps {}

const CloseButton = ({ children, ...others }: CloseButtonProps) => (
  <StyledButton startIcon={<CloseRounded />} color="inherit" size="small" {...others}>
    {children}
  </StyledButton>
)

export default CloseButton
