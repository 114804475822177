import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

interface RegisterEmailAddressProps extends SvgIconProps {}

const RegisterEmailAddress = (props: RegisterEmailAddressProps) => (
  <SvgIcon {...props}>
    <path d="M0,0H24V24H0Z" fill="none" />
    <path d="M6,8a4,4,0,1,1,4,4A4,4,0,0,1,6,8Zm4.5,6.5a2.961,2.961,0,0,1,.049-.483C10.356,14.006,10.172,14,10,14c-2.67,0-8,1.34-8,4v1a1,1,0,0,0,1,1h7.55a3.048,3.048,0,0,1-.05-.5Zm11.5,0v5A1.5,1.5,0,0,1,20.5,21h-7A1.5,1.5,0,0,1,12,19.5v-5A1.5,1.5,0,0,1,13.5,13h7A1.5,1.5,0,0,1,22,14.5Zm-1.5,1.8-2.546,1.592a1.783,1.783,0,0,1-1.908,0L13.5,16.3v3.2h7Zm0-1.8h-7L17,16.688Z" />
  </SvgIcon>
)

export default RegisterEmailAddress
