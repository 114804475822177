import React from 'react'

import { Typography } from '@mui/material'
import { useAuditionPlayer } from '@/contexts/auditionPlayer'

const secondsToHms = (seconds: number) => {
  if (isNaN(seconds)) {
    return '--:--'
  }
  const time = new Date(seconds * 1000).toISOString().substring(11, 19)
  if (time[0] === '0' && time[1] === '0') {
    return time.substring(3)
  }
  return time
}

interface TimeBoxProps {
  audio: () => HTMLAudioElement
}

const TimeBox = ({ audio }: TimeBoxProps) => {
  const { useSeeking, useSliderValue } = useAuditionPlayer()
  const seeking = useSeeking()
  const sliderValue = useSliderValue()
  return (
    <Typography sx={{ fontSize: '1rem', color: 'textSecondary' }}>
      {secondsToHms(seeking ? audio()?.duration * sliderValue : audio()?.currentTime || 0)}
    </Typography>
  )
}

export default TimeBox
