import { styled, ListItemText } from '@mui/material'

const HitListItem = styled(ListItemText)({
  height: 'auto',
  padding: '.5rem 1rem',
  '&:not(:last-of-type)': {
    borderBottom: '.5px solid #C5C5C5',
  },
})

export default HitListItem
