import { styled, Box } from '@mui/material'

const Content = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '488px',
  },
}))

export default Content
