import React from 'react'
import { useAudiobookInfo } from '@/hooks/audiobook'
import ListItem, { ItemProps } from '.'

const ItemAsync = (props: ItemProps) => {
  const audiobook = useAudiobookInfo(props.collectionId, true)

  return audiobook.collectionId ? <ListItem {...{ ...props, ...audiobook }} /> : <></>
}

export default ItemAsync
