import { styled } from '@mui/material'
import { InputBase } from '@mui/material'

const SearchBoxInput = styled(InputBase)({
  width: '100%',

  '& .MuiInputBase-input': {
    width: '100%',
    fontSize: '1.6rem',
    transform: 'scale(0.8725)',
  },
})

export default SearchBoxInput
