import React from 'react'
import { BillItem } from '@/contexts/connect'
import { Box, Button, Drawer, styled, Typography, Divider, List, ListItem, ListItemText } from '@mui/material'
import { DefaultButton } from '@/components/atoms'

const BottomDrawer = styled(Drawer)(() => ({
  '& > div.MuiDrawer-paper': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
}))
const StyledHeading3 = styled(Typography)(() => ({
  padding: ' 0.8rem 0 0.8rem 0',
}))
const StyledBox = styled(Box)(() => ({
  paddingTop: '0',
  paddingLeft: '1.6rem',
  paddingRight: '1.6rem',
  paddingBottom: '1.6rem',
  '& > div:last-child': {
    marginBottom: 0,
  },
}))
const CloseButtonBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  paddingBottom: '.8rem',
}))
const StickingOutCloseButton = styled(Button)(() => ({
  cursor: 'pointer',
  color: '#fff',
  fontWeight: 'bold',
  fontSize: '1.3rem',
  marginRight: '0.8rem',
}))
const StyledList = styled(List)(() => ({
  backgroundColor: 'white',
  borderRadius: '.4rem .4rem 0 0',
  boxShadow: '0px 8px 10px -5px rgb(0 0 0 / 20%),0px 16px 24px 2px rgb(0 0 0 / 14%),0px 6px 30px 5px rgb(0 0 0 / 12%)',
}))
const StyledListItem = styled(ListItem)(() => ({
  paddingTop: '1.6rem',
  paddingBottom: '1.6rem',
}))

const DetailsListItem = styled(ListItemText)({
  marginTop: 0,
  marginBottom: '0.4rem',

  display: 'flex',
  width: '100%',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',

  '& > span': {
    display: 'inline',
    fontSize: '1.4rem',
  },

  '& > span:first-of-type': {
    marginRight: '0.8rem',
  },
})

export interface PurchaseDetailsButtonProps extends Pick<BillItem, 'contentId' | 'price' | 'paid' | 'displayName'> {
  authors?: Queries.audiobookInfoQuery['contentfulAudiobook']['authors']
  narrators?: Queries.audiobookInfoQuery['contentfulAudiobook']['narrators']
}

const PurchaseDetailsButton = ({ displayName, price, paid, authors, narrators }: PurchaseDetailsButtonProps) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false)

  return (
    <>
      <DefaultButton
        variant="contained"
        sx={{
          fontWeight: 'bold',
          width: { sx: '8.8rem', md: '100%' },
        }}
        onClick={() => setDrawerOpen(true)}
      >
        購入内容
      </DefaultButton>

      <BottomDrawer anchor="bottom" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <CloseButtonBox>
          <StickingOutCloseButton onClick={() => setDrawerOpen(false)} size="small">
            閉じる
          </StickingOutCloseButton>
        </CloseButtonBox>

        <StyledList>
          <StyledListItem>
            <Typography>購入内容</Typography>
          </StyledListItem>

          <Divider />

          <StyledListItem>
            <StyledHeading3 variant="h3">{displayName}</StyledHeading3>
          </StyledListItem>
          <StyledBox>
            {authors?.length > 0 && (
              <DetailsListItem
                secondaryTypographyProps={{ component: 'span' }}
                primary="著者名:"
                secondary={authors
                  .map((author) => author.names.find((name) => name.languageAndScriptCode === 'ja-Hani').text)
                  .join(' ')}
              />
            )}
            {narrators?.length > 0 && (
              <DetailsListItem
                secondaryTypographyProps={{ component: 'span' }}
                primary="ナレーター:"
                secondary={narrators
                  .map((narrator) => narrator.names.find((name) => name.languageAndScriptCode === 'ja-Hani').text)
                  .join(' ')}
              />
            )}

            <DetailsListItem
              primary="購入ポイント:"
              secondary={`${Number(price).toLocaleString()}pt`}
              secondaryTypographyProps={{ component: 'span' }}
            />
            <DetailsListItem primary="購入日:" secondary={paid} secondaryTypographyProps={{ component: 'span' }} />
          </StyledBox>
        </StyledList>
      </BottomDrawer>
    </>
  )
}

export default PurchaseDetailsButton
