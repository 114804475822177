import React, { useEffect, useRef } from 'react'
import { styled } from '@mui/material'
import { connectSearchBox } from 'react-instantsearch-dom'
import { SearchBoxProvided } from 'react-instantsearch-core'
import { Box, Divider } from '@mui/material'
import { SearchBoxInput, SearchIconButton, ResetIconButton } from '@/components/atoms'
import { useLocation } from '@reach/router'
import queryString from 'query-string'

export interface SearchBoxProps extends Partial<SearchBoxProvided> {
  onChange: (event: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void
  onReset: (event: React.SyntheticEvent<HTMLElement>) => void
  placeholder: string
}

const ResetIcon = styled(Box)({
  height: '2.2rem',
  width: '2.2rem',
  marginRight: '0.8rem',
})

const SearchBoxContent = styled(Box)({
  maxWidth: '25.6rem',
  height: '3.2rem',
  padding: '0',
  border: '1px solid #0000003A',
  borderRadius: 4,
  display: 'flex',
  flexDirection: 'row',

  '& > .searchInput': {
    flex: 1,
  },
})

const SearchBox = ({ currentRefinement, refine, onChange, onSubmit, onReset, placeholder }: SearchBoxProps) => {
  const { pathname, search } = useLocation()
  const buttonRef = useRef<HTMLButtonElement>()
  const handleOnChange = (event: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    refine(event.currentTarget.value)
    onChange(event)
  }

  const handleOnReset = (event: React.SyntheticEvent<HTMLElement>) => {
    refine('')
    onReset(event)
  }

  useEffect(() => {
    if (pathname?.includes('/search')) {
      const { query } = queryString.parse(search)
      refine(query || '')
    }
    return () => {
      if (pathname?.includes('/search')) {
        // Windows Edge限定の挙動で、検索画面内で遷移をしたときにフォーカスが残り続ける対策のため、exhaustive-deps の指摘は該当しない
        // なお、試聴プレイヤーの設計見直しが行われているため、本挙動は今は再現しないかもしれない
        // eslint-disable-next-line react-hooks/exhaustive-deps
        buttonRef?.current?.focus()
      }
      refine('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, search])

  return (
    <form
      noValidate
      action=""
      role="search"
      onSubmit={(e) => {
        // iOS/Androidでinputタグにフォーカスが残り続けてIMEが開いたままになるため、検索ボタンにフォーカスを移して閉じている。
        buttonRef?.current?.focus()
        return onSubmit(e)
      }}
    >
      <SearchBoxContent>
        <SearchBoxInput
          className="searchInput"
          autoFocus={false}
          color="secondary"
          type="text"
          value={currentRefinement}
          onChange={handleOnChange}
          placeholder={placeholder}
        />
        <ResetIcon>
          {currentRefinement && (
            <ResetIconButton color="secondary" onMouseDown={handleOnReset} onTouchStart={handleOnReset} />
          )}
        </ResetIcon>
        <Box mx={0.4} my={0.1}>
          <Divider orientation="vertical" />
        </Box>
        <SearchIconButton ref={buttonRef} disableFocusRipple type="submit" />
      </SearchBoxContent>
    </form>
  )
}

export default connectSearchBox<SearchBoxProps>(SearchBox)
