import React, { MouseEventHandler } from 'react'

import { Box, Button, Typography } from '@mui/material'
import { SnsLinkBox, SpeechBalloon } from '@/components/organisms'
import { useConnect } from '@/contexts/connect'
import { DoneRounded } from '@mui/icons-material'

interface PageApplyButtonSetProps {
  onClick: MouseEventHandler
  applyCampaign: boolean
  remainingQuantity: number
  snsTitle?: string
  slug?: string
}
const PageApplyButtonSet = ({ onClick, applyCampaign, remainingQuantity, snsTitle, slug }: PageApplyButtonSetProps) => {
  const {
    campaign: {
      purchase: { useGet },
    },
  } = useConnect()
  const { error } = useGet(slug)
  const campaignPurchased = error?.message === '409'

  return (
    <Box>
      <Box textAlign="center" pt={4}>
        <Typography fontSize="1.5rem" fontWeight="bold">
          <Button
            variant="text"
            size="large"
            sx={{
              color: 'text.primary',
            }}
            href="https://dwjplistengo.zendesk.com/hc/ja/articles/4404333263001"
          >
            キャンペーン規約（注意事項）
          </Button>
        </Typography>
      </Box>
      {!applyCampaign && (
        <Box display="flex" justifyContent="center" mt={3} mb={1}>
          <SpeechBalloon remainingQuantity={remainingQuantity} />
        </Box>
      )}
      <Box textAlign="center" pt={3}>
        {campaignPurchased ? (
          <Button variant="outlined" size="large" disabled startIcon={<DoneRounded />}>
            プレゼント応募済
          </Button>
        ) : (
          <Button variant="contained" size="large" disabled={!applyCampaign} onClick={onClick}>
            プレゼントに応募する
          </Button>
        )}
      </Box>
      <Box textAlign="center" my={4}>
        <SnsLinkBox title={snsTitle} />
      </Box>
    </Box>
  )
}

export default PageApplyButtonSet
