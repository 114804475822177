import { Box, styled } from '@mui/material'
import React from 'react'

const StyledBoxLarge = styled(Box)({
  position: 'relative',
  background: '#fff',
  border: '1px solid #009fde',
  padding: '.9rem 0rem .9rem .9rem',
  borderRadius: '.4rem',
  width: '25.8rem',
  textAlign: 'center',
  marginBottom: '0.6rem',
  '&:after': {
    content: '""',
    position: 'absolute',
    right: '0',
    bottom: '-0.6rem',
    left: '0',
    width: '0px',
    height: '0px',
    margin: 'auto',
    borderStyle: 'solid',
    borderColor: '#009fde transparent transparent transparent',
    borderWidth: '0.6rem 0.6rem 0 0.6rem',
  },
})

const StyledBoxSmall = styled(Box)({
  position: 'relative',
  background: '#fff',
  border: '1px solid #009fde',
  padding: '0.5rem 0.9rem',
  borderRadius: '.4rem',
  width: '23.8rem',
  textAlign: 'center',
  marginBottom: '0.6rem',
  '&:after': {
    content: '""',
    position: 'absolute',
    right: '0',
    bottom: '-0.3rem',
    left: '0',
    width: '0px',
    height: '0px',
    margin: 'auto',
    borderStyle: 'solid',
    borderColor: '#009fde transparent transparent transparent',
    borderWidth: '0.3rem 0.3rem 0 0.3rem',
  },
})

export interface BalloonBoxProps {
  size?: string
  children: React.ReactNode
}

const BalloonBox = ({ size, children }: BalloonBoxProps) => {
  return size === 'small' ? <StyledBoxSmall>{children}</StyledBoxSmall> : <StyledBoxLarge>{children}</StyledBoxLarge>
}

export default BalloonBox
