import React from 'react'
import { List } from '@mui/material'
import { graphql, useStaticQuery } from 'gatsby'
import SingleCategoryMenu from '@/components/atoms/SingleCategoryMenu'

export interface CategoryMenuProps {
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

const CategoryMenu = ({ onClick }: CategoryMenuProps) => {
  const data: Queries.findAllContentsQueryQuery = useStaticQuery(graphql`
    query findAllContentsQuery {
      allContentfulParentCategory(sort: { category: { weight: DESC } }) {
        nodes {
          name
          slug
          category {
            name
            slug
            weight
          }
        }
      }
    }
  `)

  return (
    <List component="ul" disablePadding>
      {data.allContentfulParentCategory.nodes.map((parentCategory) => (
        <SingleCategoryMenu parentCategory={parentCategory} onClick={onClick} key={parentCategory.name} />
      ))}
    </List>
  )
}

export default CategoryMenu
