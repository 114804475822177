import React, { useEffect, useState } from 'react'
import { Box, useMediaQuery } from '@mui/material'
import { Bookmark } from '@/components/SVG/Icons'
import theme from '@/theme'
import { ClassRounded } from '@mui/icons-material'

interface BookmarkIconProps {
  open: boolean
  handleClose: () => void
  startDomElementRef?: React.RefObject<HTMLDivElement>
  endDomElementRef?: React.RefObject<HTMLDivElement | HTMLButtonElement>
}

export const ANIMATE_DURATION = 150

const BookmarkAnimatedIcon = ({
  open,
  handleClose,
  startDomElementRef,
  endDomElementRef,
}: BookmarkIconProps): JSX.Element => {
  const isPC = useMediaQuery(theme.breakpoints.up('md'))
  const animateRef = React.useRef<HTMLDivElement>(null)
  const animateBlurRef = React.useRef<HTMLDivElement>(null)
  const animateFadeRef = React.useRef<HTMLDivElement>(null)
  const [startX, setStartX] = useState(0)
  const [startY, setStartY] = useState(0)
  const [blur, setBlur] = useState(false)
  const [fade, setFade] = useState(false)
  const [endX, setEndX] = useState(0)
  const [endY, setEndY] = useState(0)

  const animateBlur = [{ transform: 'scale(1.3)', opacity: 0, filter: 'blur(2px)' }]

  useEffect(() => {
    if (open) {
      play()
    } else {
      setBlur(false)
      setFade(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useEffect(() => {
    if (blur) {
      animateBlurRef.current?.animate(animateBlur, {
        duration: ANIMATE_DURATION,
        fill: 'both',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blur])

  useEffect(() => {
    if (fade) {
      animateFadeRef.current?.animate(
        [{ opacity: 1 }, { opacity: 1 }, { opacity: 1 }, { opacity: 1 }, { opacity: 0 }],
        {
          duration: ANIMATE_DURATION * 5,
          fill: 'both',
        },
      )
    }
  }, [fade])

  const play = async () => {
    if (!startDomElementRef?.current || !endDomElementRef?.current) {
      return
    }
    const HEADER_HEIGHT = 92
    const startRect = startDomElementRef.current.getClientRects()[0]
    const x = startRect.left + startRect.width / 2 - 12
    const y = startRect.top - (isPC ? HEADER_HEIGHT : 0) + startRect.height - 20
    setStartX(x)
    setStartY(y)
    const endRect = endDomElementRef.current.getClientRects()[0]
    const endX = endRect.left + endRect.width / 2 - x - 12
    const endY = endRect.top + endRect.height / 4 - y - (isPC ? HEADER_HEIGHT : 0)
    setEndX(endX)
    setEndY(endY)
    const animate = [
      { transform: 'scale(2)', left: 0, top: 0, opacity: 1 },
      { transform: 'scale(5)', left: 0, top: 0, opacity: 1 },
      { transform: 'scale(4)', left: 0, top: 0, opacity: 1 },
      { transform: 'scale(4)', left: 0, top: 0, opacity: 1 },
      { transform: 'scale(4)', left: `${(endX * 9) / 10}px`, top: `${(endY * 9) / 10}px`, opacity: 1 },
      { transform: 'scale(4)', left: `${endX}px`, top: `${endY}px`, opacity: 1 },
      { transform: 'scale(2)', left: `${endX}px`, top: `${endY}px`, opacity: 1 },
      { transform: 'scale(2)', left: `${endX}px`, top: `${endY}px`, opacity: 0 },
      { opacity: 0 },
      { opacity: 0 },
      { opacity: 0 },
      { opacity: 0 },
    ]

    const animateKeyframes = new KeyframeEffect(animateRef.current, animate, {
      duration: ANIMATE_DURATION * animate.length,
      fill: 'both',
    })
    const animation = new Animation(animateKeyframes)
    animation.play()
    setTimeout(() => {
      setBlur(true)
    }, ANIMATE_DURATION * 2)
    setTimeout(
      () => {
        setFade(true)
      },
      ANIMATE_DURATION * (animate.length - 6),
    )
    await animation.finished
    handleClose()
  }

  if (!open) return <></>

  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          top: `${startY}px`,
          left: `${startX}px`,
          zIndex: 1500,
        }}
      >
        <Box ref={animateRef} sx={{ position: 'absolute', top: 0, left: 0 }}>
          <Bookmark
            sx={{
              fill: '#0AB7BF',
            }}
          />
        </Box>
        {blur && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 1499,
              opacity: 1,
              transform: 'scale(4)',
            }}
          >
            <Box ref={animateBlurRef}>
              <Bookmark
                sx={{
                  fill: '#0AB7BF',
                }}
              />
            </Box>
          </Box>
        )}
        {fade && (
          <Box
            sx={{
              position: 'absolute',
              top: `${endY}px`,
              left: `${endX}px`,
              zIndex: 1498,
              opacity: 0,
            }}
            ref={animateFadeRef}
          >
            <ClassRounded
              sx={{
                fill: '#0AB7BF',
              }}
            />
          </Box>
        )}
      </Box>
    </>
  )
}

export default BookmarkAnimatedIcon
