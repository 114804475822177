import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import { useHydrated } from 'react-hydration-provider'

dayjs.extend(timezone)

export const useDayjs = () => {
  const hydrated = useHydrated()
  if (hydrated) {
    return (date?: string | number | dayjs.Dayjs | Date) => dayjs(date)
  } else {
    return (date?: string | number | dayjs.Dayjs | Date) => dayjs(date).tz('Asia/Tokyo')
  }
}
