import React from 'react'
import { IconButton, IconButtonProps } from '@mui/material'
import { SignIn } from '@/components/SVG/Icons'

export interface SignInIconButtonProps extends IconButtonProps {}

const SignInIconButton = (props: SignInIconButtonProps): JSX.Element => {
  return (
    <IconButton {...props} size="large">
      <SignIn fontSize="large" />
    </IconButton>
  )
}

export default SignInIconButton
