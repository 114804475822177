import { Box, List, ListItem, ListItemText, ListItemButton } from '@mui/material'
import { ChevronRightRounded } from '@mui/icons-material'
import React from 'react'
export interface NotificationProp {
  notifications: Queries.findContentfulAudiobooksQuery['allContentfulInformation']['nodes']
}

export const Notification = ({ notifications }: NotificationProp) => {
  return (
    <List component={Box} width="100%" sx={{ py: 1 }}>
      {notifications.map((n, i) => (
        <ListItemButton component="a" key={i} href={n.linkUrl} sx={{ p: 0 }}>
          <ListItem secondaryAction={<ChevronRightRounded sx={{ display: 'flex' }} />} sx={{ py: 0 }}>
            <ListItemText primaryTypographyProps={{ variant: 'body2', color: 'rgba(0, 0, 0, 0.87)' }}>
              {n.title}
            </ListItemText>
          </ListItem>
        </ListItemButton>
      ))}
    </List>
  )
}

export default Notification
