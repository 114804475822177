import theme from '@/theme'
import { ChevronLeftRounded, ChevronRightRounded } from '@mui/icons-material'
import { Box, styled } from '@mui/material'
import React from 'react'
import { useHydrated } from 'react-hydration-provider'
import { default as Slick, CustomArrowProps, Settings } from 'react-slick'
import './index.scss'

export interface CarouselSlideProps extends Settings {
  children: React.ReactNode
}

const ArrowWrapBox = styled(Box)({
  position: 'absolute',
  top: 0,
  zIndex: 10,
  width: '9rem',
  height: '32rem',
  display: 'flex',
  cursor: 'pointer',
})
const ArrowBox = styled(Box)({
  width: '8rem',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(255,255, 255, 0.7)',
  '& .MuiSvgIcon-fontSizeLarge': {
    fontSize: '4.8rem',
  },
})
const NextShadow = styled(Box)({
  width: '1rem',
  height: '100%',
  background: 'linear-gradient(to left, rgba(0,0,0,0.15), rgba(0,0,0,0) 60%)',
})
const PrevShadow = styled(Box)({
  width: '1rem',
  height: '100%',
  background: 'linear-gradient(to right, rgba(0,0,0,0.15), rgba(0,0,0,0) 60%)',
})
const NextArrow = ({ onClick }: CustomArrowProps) => (
  <Box sx={{ display: { xs: 'none', md: 'block' } }}>
    <ArrowWrapBox right={0} onClick={onClick}>
      <NextShadow />
      <ArrowBox>
        <ChevronRightRounded color="inherit" fontSize="large" />
      </ArrowBox>
    </ArrowWrapBox>
  </Box>
)
const PrevArrow = ({ onClick }: CustomArrowProps) => (
  <Box sx={{ display: { xs: 'none', md: 'block' } }}>
    <ArrowWrapBox left={0} onClick={onClick}>
      <ArrowBox>
        <ChevronLeftRounded color="inherit" fontSize="large" />
      </ArrowBox>
      <PrevShadow />
    </ArrowWrapBox>
  </Box>
)

const StyledSlick = styled(Slick)({
  '&.slick-slider': {
    overflow: 'hidden',
    '& .slick-list': {
      border: 'none',
    },
    '& .slick-dots': {
      border: 'none',
      position: 'relative',
      bottom: '0',
    },
  },

  '& .slick-dots': {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  '& .slick-dots li': {
    width: '3.2rem',
    height: '3.2rem',
    marginLeft: '0.4rem',
    marginRight: '0.4rem',
  },

  '& .slick-dots li button': {
    marginTop: '0!important',
    width: '3.2rem',
    height: '3.2rem',
    borderRadius: '1.6rem',
    ['@media(pointer: fine)']: {
      '&:hover': {
        backgroundColor: '#eee',
      },
    },
  },

  '& .slick-dots li button:before': {
    content: '""',
    width: '.8rem',
    height: '.8rem',
    borderRadius: '.4rem',
    marginLeft: '1.2rem',
    marginTop: '1.2rem',
    opacity: '0.26',
    backgroundColor: '#000',
  },

  '& .slick-dots li.slick-active button': {
    '&:before': {
      width: '.8rem',
      height: '.8rem',
      borderRadius: '.4rem',
      backgroundColor: theme.palette.primary.main,
      opacity: '1',
    },
    '&:active': {
      opacity: '0.25',
    },
    ['@media(pointer: fine)']: {
      '&:hover': {
        backgroundColor: '#eee',
      },
    },
  },
})

const Ul = styled('ul')({
  padding: '0rem',
  margin: '0rem',
})

const PlaceholderBox = styled(Box)({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
})

const CarouselSlide = ({ children, ...others }: CarouselSlideProps) => {
  const hydrated = useHydrated()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const cloneChild = (child: any) => {
    switch (typeof child) {
      case 'string':
      case 'number':
        return child
      case 'object':
        return React.cloneElement(child)
      default:
        return null
    }
  }

  const getPlaceholderChildren = () => {
    const childrenArray = React.Children.toArray(children)
    // NOTE: 無限に出すわけにもいかないので、最初と前後の3つ表示する
    switch (childrenArray.length) {
      case 0:
        return childrenArray
      case 1:
        return [cloneChild(childrenArray[0]), childrenArray[0], cloneChild(childrenArray[0])]
      case 2:
        return childrenArray
          .slice(0, 2)
          .reverse()
          .concat([cloneChild(childrenArray[1])])
      default:
        return [childrenArray[childrenArray.length - 1]].concat(childrenArray.slice(0, 2))
    }
  }

  return hydrated ? (
    <StyledSlick
      appendDots={(dots) => (
        <div>
          {/* 本divタグはslickがスタイルを付与するdomのためBoxのスタイル調整無効、dotsの上に4.5pxが付与されている */}
          <Box pt={1.5}>
            <Ul> {dots} </Ul>
          </Box>
        </div>
      )}
      prevArrow={<PrevArrow />}
      nextArrow={<NextArrow />}
      {...others}
    >
      {children}
    </StyledSlick>
  ) : (
    <PlaceholderBox>{getPlaceholderChildren()}</PlaceholderBox>
  )
}

export default CarouselSlide
