import React from 'react'
import {
  Box,
  Link,
  List,
  ListItem as MuiListItem,
  ListItemText,
  ListItemAvatar,
  Typography,
  Theme,
  styled,
} from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { CenteringImg } from '@/components/atoms'

const StyledListItemAvatar = styled(ListItemAvatar)({
  marginRight: '1.6rem',
})

const StyledLiquidListItemAvatar = styled(StyledListItemAvatar)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    padding: '0',
    margin: '0',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
}))

const ListItem = styled(MuiListItem)({
  display: 'flex',
  alignItems: 'flex-start',
  paddingTop: '1.6rem',
})

const LiquidListItem = styled(ListItem)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    flexDirection: 'column',
    width: '23.6rem',
    marginLeft: '1.6rem',
    marginBottom: '2.4rem',
  },
}))

const ChildList = styled(List)({
  padding: 0,
  '& > *': {
    paddingLeft: 0,
    paddingRight: 0,
  },
})

const LiquidChildList = styled(ChildList)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: '20.4rem',
    marginTop: '1.6rem',
  },
}))

const StyledListItemText = styled(ListItemText)({
  marginTop: 0,
})

const ShadowImg = styled('img')({
  borderRadius: '4px',
  boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.32)',
})

const StyleClampText = styled(Typography)({
  display: '-webkit-box',
  WebkitLineClamp: '2 !important',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  lineHeight: '1.5em', // for IE
  maxHeight: '3em', // for IE
})

export interface ItemProps {
  page: Pick<Queries.ContentfulPage, 'title' | 'slug' | 'shortDescription' | 'headerImage'>
  isTop?: boolean
}

const Item = ({ page: { title, slug, shortDescription, headerImage }, isTop }: ItemProps) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const showDivider = isTop ? true : isMobile
  const ListItemComponent = isTop ? ListItem : LiquidListItem
  const ChildListComponent = isTop ? ChildList : LiquidChildList
  const width = isTop ? 80 : isMobile ? 80 : 204
  const height = isTop ? 56 : isMobile ? 56 : 144

  const AvatarComponent = (
    <Link href={slug && `/page/${slug}`}>
      {headerImage ? (
        <CenteringImg width={width} height={height} coverArt={headerImage} title={title} />
      ) : (
        <ShadowImg src={`/assets/images/page/placeholder.svg`} alt={title} width={width} height={height} />
      )}
    </Link>
  )

  return (
    <ListItemComponent divider={showDivider}>
      {isTop ? (
        <StyledListItemAvatar>{AvatarComponent}</StyledListItemAvatar>
      ) : (
        <StyledLiquidListItemAvatar>{AvatarComponent}</StyledLiquidListItemAvatar>
      )}

      <StyledListItemText>
        <ChildListComponent>
          <Box mb={1}>
            <Link href={slug && `/page/${slug}`} color="textPrimary">
              <StyleClampText variant="h2">{title}</StyleClampText>
            </Link>
          </Box>
          <StyleClampText variant="body1" color="textSecondary">
            {shortDescription}
          </StyleClampText>
        </ChildListComponent>
      </StyledListItemText>
    </ListItemComponent>
  )
}

export default Item
