import React from 'react'
import { List as MuiList, ListProps as MuiListProps, styled } from '@mui/material'

const StyledList = styled(MuiList)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: 0,
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginTop: '2.4rem',
    marginBottom: '2.4rem',
  },
}))

export interface ListProps extends MuiListProps {}

const List = (props: ListProps) => <StyledList {...props}>{props.children}</StyledList>

export default List
