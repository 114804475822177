import { ErrorOutlineRounded, WarningRounded } from '@mui/icons-material'
import { Snackbar, SnackbarProps, Slide, Button, Typography, Alert } from '@mui/material'
import React from 'react'

export enum BookmarkStatus {
  Added = 'しおりを追加しました',
  Added1st = 'しおりを追加しました\n右上のアイコンから確認できます',
  Cancelled = '操作を取り消しました',
  AlreadyAdded = 'すでに追加済みです',
  ReachedLimit = 'しおりの数が上限(100件)に達しました',
  CannotAddDueToLimit = 'しおりの数が上限(100件)に達しているため追加できません',
  FailedToAdd = '追加に失敗しました',
}
export interface BookmarkSnackbarProps extends SnackbarProps {
  status: BookmarkStatus
  onClick?: () => void
}

const BookmarkSnackbar = ({ onClick, status, ...rest }: BookmarkSnackbarProps) => {
  const snackbarProps: SnackbarProps = {
    TransitionComponent: Slide,
    autoHideDuration: 5000,
    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
  }
  const CancelButton = () => (
    <Button size="small" color="primary" sx={{ fontWeight: 'bold', minWidth: '6.2rem' }} onClick={onClick}>
      取り消す
    </Button>
  )
  switch (status) {
    case BookmarkStatus.Added:
      return <Snackbar {...snackbarProps} {...rest} message={status} action={onClick && CancelButton()} />
    case BookmarkStatus.Added1st:
      return (
        <Snackbar
          {...snackbarProps}
          {...rest}
          message={status.split('\n').map((line, i) => (
            <Typography key={i} variant="body2" color="inherit">
              {line}
            </Typography>
          ))}
          action={onClick && CancelButton()}
        />
      )
    case BookmarkStatus.ReachedLimit:
      return (
        <Snackbar {...snackbarProps} {...rest}>
          <Alert severity="warning" variant="filled" icon={<WarningRounded />} action={onClick && CancelButton()}>
            {status}
          </Alert>
        </Snackbar>
      )
    case BookmarkStatus.CannotAddDueToLimit: {
      // ✕ボタンの色が player側の強いcssで rgba(0,0,0,0.87) になってしまうので、!importantで上書きしている。
      // 本来はplayer側でやる仕事であるが他のボタンの色設定が辛いためここでやっている。
      return (
        <Snackbar {...snackbarProps} {...rest}>
          <Alert
            severity="error"
            variant="filled"
            icon={<ErrorOutlineRounded />}
            onClose={(e) => rest?.onClose?.(e, null)}
            sx={{ '& .MuiAlert-action svg': { color: '#fff !important' } }}
          >
            {status}
          </Alert>
        </Snackbar>
      )
    }
    case BookmarkStatus.Cancelled:
    case BookmarkStatus.AlreadyAdded:
    default:
      return <Snackbar {...snackbarProps} {...rest} message={status} />
  }
}

export default BookmarkSnackbar
