import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

interface SignInProps extends SvgIconProps {}

const SignIn = (props: SignInProps) => (
  <SvgIcon {...props}>
    <path d="M10.3,7.7h0a.984.984,0,0,0,0,1.4L12.2,11H3a1,1,0,0,0-1,1H2a1,1,0,0,0,1,1h9.2l-1.9,1.9a.984.984,0,0,0,0,1.4h0a.984.984,0,0,0,1.4,0l3.59-3.59a1,1,0,0,0,0-1.41L11.7,7.7A.984.984,0,0,0,10.3,7.7ZM20,19H13a1,1,0,0,0-1,1h0a1,1,0,0,0,1,1h7a2.006,2.006,0,0,0,2-2V5a2.006,2.006,0,0,0-2-2H13a1,1,0,0,0-1,1h0a1,1,0,0,0,1,1h7Z" />
  </SvgIcon>
)

export default SignIn
