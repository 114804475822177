import React from 'react'
import { Box, Divider, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { AddPoint } from '@/components/SVG/Icons'
import { Confirm } from '@/components/organisms'

export interface JoinOverlayProps {
  open: boolean
  onClose: () => void
}

const JoinOverlay = (props: JoinOverlayProps) => (
  <Confirm {...props}>
    <Box px={2} py={3}>
      <Typography variant="body1" color="textSecondary">
        ご購入の前にドワンゴジェイピーの月額コース入会をお願いします。
      </Typography>
    </Box>
    <Divider />

    <List>
      <ListItemButton href="/information/purchase_points">
        <ListItemIcon>
          <AddPoint fontSize="large" color="secondary" />
        </ListItemIcon>
        <ListItemText primary="月額コース入会はこちらから" />
      </ListItemButton>
    </List>
  </Confirm>
)

export default JoinOverlay
